import React, { useEffect, useState } from "react";
import axios from "axios";
import utils from "../../utils";
import {
  Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography,
  Select, MenuItem, FormControl, InputLabel, CircularProgress, Stack, Grid, Dialog, DialogTitle, DialogContent,
  DialogContentText, DialogActions, Divider, Checkbox, FormControlLabel, IconButton, Tooltip,
} from '@mui/material';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import JSZip from "jszip";

const TabellaPresenze = () => {

  const now = new Date();
  // fino al 15 metti di default il mese prima
  if (now.getDate() <= 15) {
    now.setMonth(now.getMonth() - 1);
  }

  // Calcola il giorno della settimana per il 1° del mese selezionato
  const calcolaPrimoGiornoSettimana = (anno, mese) => {
    const giorno = new Date(anno, mese, 0).getDay();
    return giorno === 0 ? 7 : giorno; // Se è domenica, ritorna 7 (che rappresenterà lunedì nel tuo sistema), altrimenti ritorna il giorno così com'è
  };

  const giorniInMeseX = (anno) => {
    return [
      31, anno % 4 ? 28 : 29, 31, 30, 31, 30,
      31, 31, 30, 31, 30, 31
    ];
  }

  const [loading, setLoading] = useState(true);
  const [statusMessage, setStatusMessage] = useState("");
  const [isMaster, setIsMaster] = useState(false);
  const [azienda, setAzienda] = useState(null);
  const [aziende, setAziende] = useState([]);
  const [isError, setError] = useState(true);
  const [anno, setAnno] = useState(now.getFullYear());
  const [mese, setMese] = useState(now.getMonth()); // 0 rappresenta gennaio
  const [numGiorniMese, setNumGiorniMese] = useState(giorniInMeseX(now.getFullYear())[now.getMonth()]);
  const [dipendenti, setDipendenti] = useState(['Dipendente 1']);
  const [tabVisualizzata, setTabVisualizzata] = useState(0);
  const [ruoli, setRuoli] = useState(['']);
  const [note, setNote] = useState(['']);
  const [orari, setOrari] = useState(['']);
  const [primoGiornoSettimana, setPrimoGiornoSettimana] =
    useState(calcolaPrimoGiornoSettimana(now.getFullYear(), now.getMonth()));
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedIndexToRemove, setSelectedIndexToRemove] = useState(null);

  const [oraEntrata, setOraEntrata] = useState('');
  const [oraUscita, setOraUscita] = useState('');
  const [oraInizioPausa, setOraInizioPausa] = useState('');
  const [oraFinePausa, setOraFinePausa] = useState('');
  const [oreTotali, setOreTotali] = useState('');

  const [selectedDays, setSelectedDays] = useState([]);
  const [hours, setHours] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState(null);

  // Dialog Autocompile ore
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [openInfoDialog, setOpenInfoDialog] = useState(false);

  const initialState = {
    oreLavorate: Array(numGiorniMese).fill(''),
    notturno: Array(numGiorniMese).fill(''),
    straordinarioNotturno: Array(numGiorniMese).fill(''),
    festivo: Array(numGiorniMese).fill(''),
    straordinari: Array(numGiorniMese).fill(''),
    ferie: Array(numGiorniMese).fill(''),
    festivitaGoduta: Array(numGiorniMese).fill(''),
    permessi: Array(numGiorniMese).fill(''),
    assenze: Array(numGiorniMese).fill(''),
    malattia: Array(numGiorniMese).fill(''),
    infortunio: Array(numGiorniMese).fill(''),
    maternita: Array(numGiorniMese).fill(''),
    oreViaggio: Array(numGiorniMese).fill(''),
    flessibilita: Array(numGiorniMese).fill(''),
    altriEventi: Array(numGiorniMese).fill(''),
  };

  const [presenze, setPresenze] = useState([initialState]);
  const [presenzeInviate, setPresenzeInviate] = useState(false);

  const mesi = [
    'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno',
    'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'
  ];

  const giorniSettimana = ['Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Dom'];

  useEffect(() => {
    start();
  }, []);

  const start = async () => {
    try {
      const res = await axios.get("/api/me", {
        headers: utils.getAuthHeaders(),
        validateStatus: () => true,
      });

      if (res.status === 200) {
        const { success, error, me } = res.data;
        if (success) {
          if (me.op) {
            window.location.href = "/op";
          } else if (me.is_master) {
            setIsMaster(true);
            loadAziende();
          } else {
            loadData(now.getMonth(), now.getFullYear());
          }
        } else if (error) {
          setStatusMessage(error);
          setLoading(false);
        }
        return;
      }
    } catch (error) {
      setStatusMessage(error);
    }

    setLoading(false);

    if (process.env.REACT_APP_ENV !== "test") {
      window.location.href = "/login?backto=%2Fcli%2Fpresenze";
    }
  }

  const loadAziende = async () => {
    try {
      const res = await axios.get(`/api/sottoaziende`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setAziende(res.data.aziende);
      } else {
        setError(true);
        setStatusMessage(error);
      }
    } catch (error) {
      setError(true);
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
    if (process.env.REACT_APP_ENV == "test") {
      setAziende([{ id: 1, nome: 'Azienda Test' }]);
    }
  }

  const loadData = async (m, y, id = null) => {
    try {
      const res = await axios.get(`/api/presenzecliente?m=${m}&y=${y}` + (isMaster ? `&id=${id}` : ""), {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        if (res.data.presenze) {
          setPresenze(res.data.presenze);
          setDipendenti(res.data.dipendenti);
          setOrari(res.data.orari);
          setRuoli(res.data.ruoli);
          setNote(res.data.note);
          setUploadedFiles(res.data.docId);
          setPresenzeInviate(res.data.inviato);
        }
      } else {
        setError(true);
        setStatusMessage(error);
      }
    } catch (error) {
      setError(true);
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  }

  const confirm = async (invia) => {
    setOpenConfirm(false);
    setStatusMessage("");
    setLoading(true);
    try {
      const json = { month: mese, year: anno, data: { presenze, dipendenti, ruoli, orari, note }, id: isMaster ? azienda : undefined };

      const payload = new FormData();

      if (selectedFiles.length) {
        if (selectedFiles.length == 1) {
          payload.append("doc", selectedFiles[0].file);
          json.doc = selectedFiles[0].name;
        } else {
          const zip = new JSZip();
          // Aggiungi tutti i file al contenuto del file zip
          for (let i = 0; i < selectedFiles.length; i++) {
            const file = selectedFiles[i];
            zip.file(file.name, file.file);
          }
          // Crea un blob zip contenente tutti i file
          const zippedBlob = await zip.generateAsync({ type: 'blob' });
          payload.append("doc", zippedBlob);
          json.doc = 'documenti.zip';
        }
      }

      payload.append("data", JSON.stringify(json));

      const res = await axios.post(`/api/${invia ? 'inviapresenze' : 'aggiornapresenze'}`, payload, {
        headers: {
          ...utils.getAuthHeaders(),
          "Content-Type": "multipart/form-data",
        },
      });
      const { success, error } = res.data;
      if (success) {
        setError(false);
        if (invia) {
          setStatusMessage("Presenze inviate con successo.");
          setPresenzeInviate(true);
        } else {
          setStatusMessage("Presenze aggiornate con successo.");
        }
      } else {
        setError(true);
        setStatusMessage(error);
      }
    } catch (error) {
      setError(true);
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  }

  const sumOre = (ore) => {
    let totMinuti = 0
    let totOre = 0;
    for (const ora of ore) {
      if (!ora) {
        continue;
      }
      const spl = ora.split('.');
      totOre += parseInt(spl[0]);
      if (parseInt(spl[1])) {
        if (spl[1] === '5') {
          totMinuti += 30;
        } else {
          totMinuti += parseInt(spl[1].padEnd(2, '0'));
        }
      }
    }
    return `${totOre + Math.floor(totMinuti / 60)}:${(totMinuti % 60).toString().padStart(2, '0')}`
  }

  // Funzione per cambiare il mese selezionato
  const handleMeseChange = (event) => {
    setLoading(true);
    setStatusMessage("");
    const nuovoMese = event.target.value;
    setMese(nuovoMese);
    const giorniInMese = giorniInMeseX(anno)[nuovoMese];
    setNumGiorniMese(giorniInMese);
    const primoGiornoSett = calcolaPrimoGiornoSettimana(anno, nuovoMese);
    setPrimoGiornoSettimana(primoGiornoSett);
    setPresenze((prevState) => prevState.map((entry) => ({
      ...entry,
      oreLavorate: Array(giorniInMese).fill(''),
      notturno: Array(giorniInMese).fill(''),
      straordinarioNotturno: Array(giorniInMese).fill(''),
      festivo: Array(giorniInMese).fill(''),
      straordinari: Array(giorniInMese).fill(''),
      ferie: Array(giorniInMese).fill(''),
      festivitaGoduta: Array(giorniInMese).fill(''),
      permessi: Array(giorniInMese).fill(''),
      assenze: Array(giorniInMese).fill(''),
      malattia: Array(giorniInMese).fill(''),
      infortunio: Array(giorniInMese).fill(''),
      maternita: Array(giorniInMese).fill(''),
      oreViaggio: Array(giorniInMese).fill(''),
      flessibilita: Array(giorniInMese).fill(''),
      altriEventi: Array(giorniInMese).fill(''),
    })));
    setNote(['']);
    loadData(nuovoMese, anno, azienda);
  };

  const handleAnnoChange = (event) => {
    setLoading(true);
    setStatusMessage("");
    const nuovoAnno = event.target.value;
    setAnno(nuovoAnno);
    const giorniInMese = giorniInMeseX(nuovoAnno)[mese];
    setNumGiorniMese(giorniInMese);
    const primoGiornoSett = calcolaPrimoGiornoSettimana(nuovoAnno, mese);
    setPrimoGiornoSettimana(primoGiornoSett);
    setPresenze((prevState) => prevState.map((entry) => ({
      ...entry,
      oreLavorate: Array(giorniInMese).fill(''),
      notturno: Array(giorniInMese).fill(''),
      straordinarioNotturno: Array(giorniInMese).fill(''),
      festivo: Array(giorniInMese).fill(''),
      straordinari: Array(giorniInMese).fill(''),
      ferie: Array(giorniInMese).fill(''),
      festivitaGoduta: Array(giorniInMese).fill(''),
      permessi: Array(giorniInMese).fill(''),
      assenze: Array(giorniInMese).fill(''),
      malattia: Array(giorniInMese).fill(''),
      infortunio: Array(giorniInMese).fill(''),
      maternita: Array(giorniInMese).fill(''),
      oreViaggio: Array(giorniInMese).fill(''),
      flessibilita: Array(giorniInMese).fill(''),
      altriEventi: Array(giorniInMese).fill(''),
    })));
    setNote(['']);
    loadData(mese, nuovoAnno, azienda);
  };

  // Funzione per cambiare l'azienda selezionata
  const handleAziendaChange = (event) => {
    const nuovaAzienda = Number(event.target.value);
    setLoading(true);
    setStatusMessage("");
    setAzienda(nuovaAzienda);
    const giorniInMese = giorniInMeseX(anno)[mese];
    setPresenze((prevState) => prevState.map((entry) => ({
      ...entry,
      oreLavorate: Array(giorniInMese).fill(''),
      notturno: Array(giorniInMese).fill(''),
      straordinarioNotturno: Array(giorniInMese).fill(''),
      festivo: Array(giorniInMese).fill(''),
      straordinari: Array(giorniInMese).fill(''),
      ferie: Array(giorniInMese).fill(''),
      festivitaGoduta: Array(giorniInMese).fill(''),
      permessi: Array(giorniInMese).fill(''),
      assenze: Array(giorniInMese).fill(''),
      malattia: Array(giorniInMese).fill(''),
      infortunio: Array(giorniInMese).fill(''),
      maternita: Array(giorniInMese).fill(''),
      oreViaggio: Array(giorniInMese).fill(''),
      flessibilita: Array(giorniInMese).fill(''),
      altriEventi: Array(giorniInMese).fill(''),
    })));
    setDipendenti(['']);
    setOrari(['']);
    setRuoli(['']);
    setNote(['']);
    loadData(mese, anno, nuovaAzienda);
  };

  const handleTabVisualizzataChange = (event) => {
    setTabVisualizzata(Number(event.target.value));
  }

  // Funzione per aggiungere una tabella per un nuovo dipendente
  const aggiungiTabellaDipendente = () => {
    const currentLength = dipendenti.length;
    setDipendenti((prevState) => [...prevState, `Dipendente ${prevState.length + 1}`]);
    setOrari((prevState) => [...prevState, '']);
    setRuoli((prevState) => [...prevState, '']);
    setNote((prevState) => [...prevState, '']);
    setPresenze((prevState) => [...prevState, initialState]);
    setTimeout(() => setTabVisualizzata(currentLength), 100);
  };

  // Funzione per rimuovere una tabella di un dipendente
  const rimuoviTabellaDipendente = (dipendenteIndex) => {
    if (dipendenteIndex > 0) {
      setTabVisualizzata(dipendenteIndex - 1);
    }
    const nuoviDipendenti = [...dipendenti];
    nuoviDipendenti.splice(dipendenteIndex, 1);
    setDipendenti(nuoviDipendenti);

    const nuoviOrari = [...orari];
    nuoviOrari.splice(dipendenteIndex, 1);
    setOrari(nuoviOrari);

    const nuoviRuoli = [...ruoli];
    nuoviRuoli.splice(dipendenteIndex, 1);
    setRuoli(nuoviRuoli);

    const nuoveNote = [...note];
    nuoveNote.splice(dipendenteIndex, 1);
    setNote(nuoveNote);

    const nuovePresenze = [...presenze];
    nuovePresenze.splice(dipendenteIndex, 1);
    setPresenze(nuovePresenze);
  };

  // Funzione per aprire il dialog elimina tab dipendente
  const openRemoveDialog = (dipendenteIndex) => {
    setSelectedIndexToRemove(dipendenteIndex);
    setOpenDialog(true);
  };
  const closeRemoveDialog = () => {
    setOpenDialog(false);
  };
  // Gestore dell'evento onClick per il pulsante "Elimina"
  const handleRemoveButtonClick = (dipendenteIndex) => {
    openRemoveDialog(dipendenteIndex);
  };

  // Funzione per aggiornare le informazioni di un dipendente
  const handleDipendenteChange = (event, dipendenteIndex, tipo, giorno) => {
    const value = event.target.value.replace(',', '.').replace(':', '.');
    if (value === '') {
      const nuovaPresenza = [...presenze];
      nuovaPresenza[dipendenteIndex][tipo][giorno - 1] = '';
      setPresenze(nuovaPresenza);
      return;
    }

    const numeroValue = parseFloat(value);
    if (isNaN(numeroValue)) {
      return;
    }

    const splittedValue = value.split('.');
    let numero = parseInt(splittedValue[0]).toString();
    if (splittedValue.length > 1) {
      const decimalPart = splittedValue[1].substring(0, 2);
      numero = `${numero}.${decimalPart}`;
    }

    const nuovaPresenza = [...presenze];
    nuovaPresenza[dipendenteIndex][tipo][giorno - 1] = value;
    setPresenze(nuovaPresenza);
  };

  // Funzione per aggiornare il nome di un dipendente
  const handleNomeDipendenteChange = (event, dipendenteIndex) => {
    const nuovoNome = event.target.value;
    const nuoviDipendenti = [...dipendenti];
    nuoviDipendenti[dipendenteIndex] = nuovoNome;
    setDipendenti(nuoviDipendenti);
  };

  // Funzione per aggiornare il ruolo di un dipendente
  const handleRuoloChange = (event, dipendenteIndex) => {
    const nuovoNome = event.target.value;
    const nuoviRuoli = [...ruoli];
    nuoviRuoli[dipendenteIndex] = nuovoNome;
    setRuoli(nuoviRuoli);
  };

  // Funzione per aggiornare l'orario di un dipendente
  const handleOrarioChange = (event, dipendenteIndex) => {
    const nuovoNome = event.target.value;
    const nuoviOrari = [...orari];
    nuoviOrari[dipendenteIndex] = nuovoNome;
    setOrari(nuoviOrari);
  };

  // Funzione per aggiornare il ruolo di un dipendente
  const handleNoteChange = (event, dipendenteIndex) => {
    const nuovoNome = event.target.value;
    const nuoveNote = [...note];
    nuoveNote[dipendenteIndex] = nuovoNome;
    setNote(nuoveNote);
  };

  // Funzione per calcolare il totale delle ore di un dipendente per un giorno specifico
  const calcolaTotaleGiornaliero = (idx, giorno) => {
    return sumOre(
      ['oreLavorate', 'notturno', 'straordinarioNotturno', 'festivo',
        'straordinari', 'oreViaggio', 'flessibilita', 'altriEventi']
        .map(tipo => presenze[idx][tipo][giorno - 1]));
  }

  // Funzione per calcolare il totale delle ore di un dipendente
  const calcolaTotale = (idx) => {
    return sumOre(Array.from({ length: numGiorniMese }, (_, i) => i + 1)
      .map(giorno => calcolaTotaleGiornaliero(idx, giorno).replace(':', '.')));
  }

  // Funzione per formattare i dati della tabella in un formato stampabile
  const formatDataForPrint = () => {
    let dataToPrint = `
  <style>
    @page { size: A4 landscape; margin: 10mm; }
    body { font-family: Arial, sans-serif; font-size: 6px; margin: 0; padding: 0; }
    table { width: 100%; border-collapse: collapse; margin-bottom: 5px; }
    th, td { border: 1px solid black; padding: 2px; text-align: center; }
    th { background-color: #f2f2f2; font-size: 8px; }
    tr:nth-child(even) { background-color: #f9f9f9; }
    .header { text-align: center; font-size: 10px; font-weight: bold; margin-bottom: 10px; }
    .grid-container { display: grid; grid-template-columns: repeat(4, 1fr); gap: 5px; margin-bottom: 5px; }
    .grid-item { font-size: 8px; padding: 5px; border-bottom: 1px solid #ddd; }
    .notes { font-size: 10px; margin-top: 12px; }
    .divider { border-top: 1px solid #333; margin: 10px 0; }
    .page-break { page-break-after: always; }
    .small-font { font-size: 8px; } /* Classe per ridurre la dimensione del carattere */
  </style>
  `;

    // Aggiungi dati della tabella per ogni dipendente
    dipendenti.forEach((dip, idx) => {
      let totalOreLavorate = sumOre(presenze[idx].oreLavorate);
      let totalNotturno = sumOre(presenze[idx].notturno);
      let totalStraordinari = sumOre(presenze[idx].straordinari);
      let totalStraordinarioNotturno = sumOre(presenze[idx].straordinarioNotturno);
      let totalFestivo = sumOre(presenze[idx].festivo);
      let totalFerie = sumOre(presenze[idx].ferie);
      let totalFestivitaGoduta = sumOre(presenze[idx].festivitaGoduta);
      let totalPermessi = sumOre(presenze[idx].permessi);
      let totalMalattie = sumOre(presenze[idx].malattia);
      let totalOreViaggio = sumOre(presenze[idx].oreViaggio);
      let totalFlessibilita = sumOre(presenze[idx].flessibilita);
      let totalAltriEventi = sumOre(presenze[idx].altriEventi);
      let totalAssenze = sumOre(presenze[idx].assenze);
      let totalInfortunio = sumOre(presenze[idx].infortunio);
      let totalMaternita = sumOre(presenze[idx].maternita);

      // Inizia un nuovo foglio per ogni dipendente
      dataToPrint += `<div class="page">`;
      dataToPrint += `<div class="grid-container">
            <div class="grid-item"><b>Dipendente:</b> ${dip}</div>
            <div class="grid-item"><b>Ruolo:</b> ${ruoli[idx]}</div>
            <div class="grid-item"><b>Orario:</b> ${orari[idx]}</div>
            <div class="grid-item"><b>Mese:</b> ${meseToName(mese)}</div>
          </div>`;
      dataToPrint += `<table>`;
      dataToPrint += `<thead>`;
      dataToPrint += `<tr><td colspan="16" style="height: 20px; border: none;"></td></tr>`; // Spazio aggiunto
      dataToPrint += `<tr>
        <th class="small-font" style="width: 6%;">Giorno</th>
        <th style="width: 6%;">Ore Lavorate</th>
        <th style="width: 6%;">Notturno</th>
        <th style="width: 6%;">Straordinari</th>
        <th style="width: 6%;">Straordinario Notturno</th> <!-- Nuova colonna -->
        <th style="width: 6%;">Festivo</th>
        <th style="width: 6%;">Ferie</th>
        <th style="width: 6%;">Fest. Goduta</th>
        <th style="width: 6%;">Permessi</th>
        <th style="width: 6%;">Assenze</th>
        <th style="width: 6%;">Malattia</th>
        <th style="width: 6%;">Infortunio</th>
        <th style="width: 6%;">Maternità</th>
        <th style="width: 6%;">Ore Viaggio</th>
        <th style="width: 6%;">Flessibilità</th> <!-- Nuova colonna -->
        <th style="width: 6%;">Altri Eventi</th>
      </tr>`;
      dataToPrint += `</thead>`;
      dataToPrint += `<tbody>`;

      for (let i = 0; i < numGiorniMese; i++) {
        const data = new Date(anno, mese, i + 1);
        const giornoSettimana = ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'][data.getDay()];
        dataToPrint += `<tr>
          <td class="small-font">${i + 1} ${giornoSettimana}</td>
          <td class="small-font">${presenze[idx].oreLavorate[i] || ''}</td>
          <td class="small-font">${presenze[idx].notturno[i] || ''}</td>
          <td class="small-font">${presenze[idx].straordinari[i] || ''}</td>
          <td class="small-font">${presenze[idx].straordinarioNotturno[i] || ''}</td> <!-- Nuova colonna -->
          <td class="small-font">${presenze[idx].festivo[i] || ''}</td>
          <td class="small-font">${presenze[idx].ferie[i] || ''}</td>
          <td class="small-font">${presenze[idx].festivitaGoduta[i] || ''}</td>
          <td class="small-font">${presenze[idx].permessi[i] || ''}</td>
          <td class="small-font">${presenze[idx].assenze[i] || ''}</td>
          <td class="small-font">${presenze[idx].malattia[i] || ''}</td>
          <td class="small-font">${presenze[idx].infortunio[i] || ''}</td>
          <td class="small-font">${presenze[idx].maternita[i] || ''}</td>
          <td class="small-font">${presenze[idx].oreViaggio[i] || ''}</td>
          <td class="small-font">${presenze[idx].flessibilita[i] || ''}</td> <!-- Nuova colonna -->
          <td class="small-font">${presenze[idx].altriEventi[i] || ''}</td>
        </tr>`;
      }

      const totalEffettivo = sumOre([
        ...presenze[idx].oreLavorate,
        ...presenze[idx].straordinari,
        ...presenze[idx].notturno,
        ...presenze[idx].oreViaggio,
        ...presenze[idx].festivo,
        ...presenze[idx].altriEventi,
        ...presenze[idx].straordinarioNotturno,
        ...presenze[idx].flessibilita
      ]);

      dataToPrint += `<tr style="font-weight: bold;">
        <td>Totale</td>
        <td>${totalOreLavorate}</td>
        <td>${totalNotturno}</td>
        <td>${totalStraordinari}</td>
        <td>${totalStraordinarioNotturno}</td>
        <td>${totalFestivo}</td>
        <td>${totalFerie}</td>
        <td>${totalFestivitaGoduta}</td>
        <td>${totalPermessi}</td>
        <td>${totalAssenze}</td>
        <td>${totalMalattie}</td>
        <td>${totalInfortunio}</td>
        <td>${totalMaternita}</td>
        <td>${totalOreViaggio}</td>
        <td>${totalFlessibilita}</td>
        <td>${totalAltriEventi}</td>
      </tr>`;

      dataToPrint += `</tbody>`;
      dataToPrint += `</table>`;

      dataToPrint += `<div class="notes" style="margin-top: 20px;"><b>Totale Effettivo:</b> ${totalEffettivo} ore</div>`;
      dataToPrint += `<div class="notes"><b>Note:</b> ${note[idx]}</div>`;
      dataToPrint += `</div>`;
      dataToPrint += `<div class="page-break"></div>`;
    });

    return dataToPrint;
  };

  // Funzione per convertire il numero del mese in nome del mese
  const meseToName = (mese) => {
    const mesi = [
      'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno',
      'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'
    ];
    return mesi[mese];
  };

  // Funzione per gestire il clic sul pulsante di stampa
  const handleStampaClick = () => {
    const dataToPrint = formatDataForPrint();
    const printWindow = window.open('', '_blank');
    printWindow.document.open();
    printWindow.document.write(`<html><head><title>Stampa</title></head><body>${dataToPrint}</body></html>`);
    printWindow.document.close();
    printWindow.print();
  };

  // Funzione per gestire il cambiamento dell'ora di entrata
  const handleChangeOraEntrata = (event) => {
    const { value } = event.target;
    setOraEntrata(value);
    // Aggiorna il totale delle ore
    calculateTotalHours(value, oraUscita, oraInizioPausa, oraFinePausa);
  };

  // Funzione per gestire il cambiamento dell'ora di uscita
  const handleChangeOraUscita = (event) => {
    const { value } = event.target;
    setOraUscita(value);
    // Aggiorna il totale delle ore
    calculateTotalHours(oraEntrata, value, oraInizioPausa, oraFinePausa);
  };

  // Funzione per gestire il cambiamento dell'ora di inizio pausa
  const handleChangeOraInizioPausa = (event) => {
    const { value } = event.target;
    setOraInizioPausa(value);
    // Aggiorna il totale delle ore
    calculateTotalHours(oraEntrata, oraUscita, value, oraFinePausa);
  };

  // Funzione per gestire il cambiamento dell'ora di fine pausa
  const handleChangeOraFinePausa = (event) => {
    const { value } = event.target;
    setOraFinePausa(value);
    // Aggiorna il totale delle ore
    calculateTotalHours(oraEntrata, oraUscita, oraInizioPausa, value);
  };

  // Funzione per calcolare il totale delle ore
  const calculateTotalHours = (entrata, uscita, inizioPausa, finePausa) => {
    // Funzione per convertire orario in minuti dall'inizio della giornata
    const timeToMinutes = (time) => {
      const [hours, minutes] = time.split(':').map(Number);
      return hours * 60 + minutes;
    };

    // Converti tutti gli orari in minuti
    const entrataMinuti = entrata ? timeToMinutes(entrata) : 0;
    const uscitaMinuti = uscita ? timeToMinutes(uscita) : 0;
    const inizioPausaMinuti = inizioPausa ? timeToMinutes(inizioPausa) : 0;
    const finePausaMinuti = finePausa ? timeToMinutes(finePausa) : 0;
    // Calcola la durata totale del lavoro escludendo il tempo di pausa
    const minutiLavorativi = uscitaMinuti - entrataMinuti;
    const minutiPausa = finePausaMinuti - inizioPausaMinuti;
    const minutiTotali = minutiLavorativi - minutiPausa;
    // Calcola ore e minuti dal totale dei minuti
    const ore = Math.floor(minutiTotali / 60);
    const minuti = minutiTotali % 60;
    // Formatta il totale delle ore nel formato "HH.MM"
    const oreFormattate = String(ore);
    const minutiFormattati = String(minuti).padStart(2, '0');

    setOreTotali(`${oreFormattate}.${minutiFormattati}`);
  };

  // Gestione Cambio Giorno e Ora Compilazione
  const handleDayChange = (event) => {
    const day = event.target.name;
    setSelectedDays((prev) =>
      prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day]
    );
  };

  const handleHoursChange = (event) => {
    const value = event.target.value.replace(',', '.').replace(':', '.');
    if (value === '') {
      setHours(value);
      return;
    }

    const numeroValue = parseFloat(value);
    if (isNaN(numeroValue)) {
      return;
    }

    const splittedValue = value.split('.');
    let numero = parseInt(splittedValue[0]).toString();
    if (splittedValue.length > 1) {
      const decimalPart = splittedValue[1].substring(0, 2);
      numero = `${numero}.${decimalPart}`;
    }
    setHours(value);
  };

  // Funzione per compilare le ore in automatico
  const handleFillTimesheet = (dipendenteIndex) => {
    if (!presenze[dipendenteIndex]) {
      console.error(`presenze[${dipendenteIndex}] is undefined`);
      return;
    }

    const newPresenze = [...presenze];
    const numGiorniMese = giorniInMeseX(anno)[mese];

    const dayMap = {
      lun: 'Lun',
      mar: 'Mar',
      mer: 'Mer',
      gio: 'Gio',
      ven: 'Ven',
      sab: 'Sab',
      dom: 'Dom',
    };

    for (let giorno = 1; giorno <= numGiorniMese; giorno++) {
      const date = new Date(anno, mese, giorno);
      const dayOfWeek = date.toLocaleString('it-IT', { weekday: 'short' }).toLowerCase();
      const shortDayOfWeek = dayMap[dayOfWeek];

      if (selectedDays.includes(shortDayOfWeek)) {
        newPresenze[dipendenteIndex].oreLavorate[giorno - 1] = hours.replace(/[,:;]/g, '.');
      }
    }

    setPresenze(newPresenze);
  };

  const handleFillAllTimesheets = () => {
    setConfirmMessage('Compilando per tutti, eventuali singole compilazioni dei dipendenti verranno sovrascritte. Sei sicuro di voler procedere?');
    setOpenConfirmDialog(true);
  };
  const fillAllTimesheets = () => {
    for (let dipendenteIndex = 0; dipendenteIndex < dipendenti.length; dipendenteIndex++) {
      handleFillTimesheet(dipendenteIndex);
    }
  };

  // Funzione per aprire il dialog dell info compilazione ore
  const handleOpenInfoDialog = () => {
    setOpenInfoDialog(true);
  };
  const handleCloseInfoDialog = () => {
    setOpenInfoDialog(false);
  };

  // Funzioni Allega File
  const handleMultiFileChange = (e) => {
    const files = e.target.files;
    const newFiles = [...selectedFiles];
    for (let i = 0; i < files.length; i++) {
      newFiles.push({ name: files[i].name, file: files[i] });
    }
    setSelectedFiles(newFiles);
  }

  const handleDeleteFile = (index) => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  }

  const handleDownload = async () => {
    try {
      const response = await axios.get(`/api/scaricadoccliente?id=${uploadedFiles}`, {
        headers: utils.getAuthHeaders(),
        responseType: "blob",
      });

      const contentDisposition = response.headers["content-disposition"];
      const filename = contentDisposition
        ? contentDisposition.split(";")[1].split("filename=")[1].trim()
        : "documento";

      const fileBlob = new Blob([response.data]);
      const fileUrl = URL.createObjectURL(fileBlob);

      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = filename;
      link.click();
      URL.revokeObjectURL(fileUrl);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      p={1}
      mx="auto"
    >
      <Typography variant="h4" gutterBottom>
        Foglio Presenze
      </Typography>

      {/* Paper Azienda Dipendente Mese Anno */}
      <Paper elevation={3} style={{ margin: '4px', width: '90vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box p={1} textAlign="center">
          <Grid container spacing={1} justifyContent="center" alignItems="center">

            {isMaster && <Grid item>
              <FormControl variant="outlined" size="small" style={{ width: '250px' }}>
                <InputLabel htmlFor="azienda">Azienda:</InputLabel>
                <Select
                  id="azienda"
                  value={azienda}
                  onChange={handleAziendaChange}
                  label="Azienda"
                >
                  {aziende.map(az => (
                    <MenuItem key={az.id} value={az.id}>{az.nome}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>}

            <Grid item>
              <FormControl variant="outlined" size="small" style={{ width: '250px' }}>
                <InputLabel htmlFor="dipendente">Dipendente:</InputLabel>
                <Select
                  id="dipendente"
                  value={tabVisualizzata}
                  onChange={handleTabVisualizzataChange}
                  label="Dipendente"
                >
                  {dipendenti.map((dip, idx) => (
                    <MenuItem key={idx} value={idx}>{dip}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl variant="outlined" size="small" style={{ width: '250px' }}>
                <InputLabel htmlFor="mese">Mese:</InputLabel>
                <Select
                  id="mese"
                  value={mese}
                  onChange={handleMeseChange}
                  label="Mese"
                >
                  {mesi.map((mese, index) => (
                    <MenuItem key={index} value={index}>{mese}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl variant="outlined" size="small" style={{ width: '250px' }}>
                <InputLabel htmlFor="anno">Anno:</InputLabel>
                <Select
                  id="anno"
                  value={anno}
                  onChange={handleAnnoChange}
                  label="Anno"
                >
                  {Array.from({ length: new Date().getFullYear() - 2022 }, (_, i) => 2024 + i).map((a) => (
                    <MenuItem key={a} value={a}>{a}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md="auto" lg="auto">
              <IconButton onClick={handleStampaClick} color="dark">
                <PrintOutlinedIcon fontSize="large" />
              </IconButton>
            </Grid>

          </Grid>
        </Box>
      </Paper>

      {/* Paper Compilazione ore */}
      <Paper elevation={2} style={{ width: '90vw', marginTop: '10px' }}>
        <Box p={1}>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            {['Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Dom'].map((day, index) => (
              <Grid item key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedDays.includes(day)}
                      onChange={handleDayChange}
                      name={day}
                    />
                  }
                  label={day}
                />
              </Grid>
            ))}
            <Grid item>
              <TextField
                variant="outlined"
                size="small"
                label="Ore"
                value={hours}
                onChange={handleHoursChange}
                style={{ width: '160px' }}
              />
            </Grid>
            <Grid item>
              <Button disabled={presenzeInviate} variant="contained" color="primary" onClick={() => handleFillTimesheet(tabVisualizzata)}>
                Compila
              </Button>
            </Grid>

            <Grid item>
              <Button disabled={presenzeInviate} variant="contained" color="primary" onClick={handleFillAllTimesheets}>
                Compila Tutti
              </Button>
            </Grid>

            <Grid item>
              <Tooltip title="Guida inserimento ore">
                <IconButton onClick={handleOpenInfoDialog}>
                  <InfoIcon style={{ color: 'orange' }} />
                </IconButton>
              </Tooltip>
            </Grid>

            {/* Dialog per conferma compila tutti */}
            <Dialog
              open={openConfirmDialog}
              onClose={() => setOpenConfirmDialog(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Conferma"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {confirmMessage}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
                  Annulla
                </Button>
                <Button onClick={() => { setOpenConfirmDialog(false); fillAllTimesheets(); }} color="primary" autoFocus>
                  Conferma
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>

          {/* Dialog per guida compilazione ore */}
          <Dialog
            open={openInfoDialog}
            onClose={handleCloseInfoDialog}
            aria-labelledby="info-dialog-title"
            aria-describedby="info-dialog-description"
          >
            <DialogTitle id="info-dialog-title">Guida Inserimento Ore</DialogTitle>
            <DialogContent>
              <DialogContentText id="info-dialog-description" style={{ fontStyle: 'italic', color: 'gray' }}>
                * Per l'inserimento corretto delle ore, la conversione è in sessantesimi. <br />
                Ad esempio, 1.30 corrisponde a 1 ora e mezza (30 minuti). <br />
                Se inserisci 8.50, significa 8 ore e 50 minuti.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseInfoDialog} color="primary">
                Chiudi
              </Button>
            </DialogActions>
          </Dialog>

        </Box>
      </Paper>

      {loading ? (<Stack sx={{ my: 3 }} alignItems="center">
        <CircularProgress disableShrink />
      </Stack>) : (<div>
        {dipendenti.length == 0 ? (<Typography />) : (

          <Paper
            key={tabVisualizzata}
            elevation={3}
            style={{ margin: '16px', width: '90vw' }}
          >
            <Box p={2} textAlign="center">
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Nome Dipendente"
                    disabled={presenzeInviate}
                    value={dipendenti[tabVisualizzata]}
                    onChange={(e) => handleNomeDipendenteChange(e, tabVisualizzata)}
                    style={{ marginBottom: '6px' }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Ruolo"
                    disabled={presenzeInviate}
                    value={ruoli[tabVisualizzata]}
                    onChange={(e) => handleRuoloChange(e, tabVisualizzata)}
                    style={{ marginBottom: '6px' }}
                    fullWidth />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Orario"
                    disabled={presenzeInviate}
                    value={orari[tabVisualizzata]}
                    onChange={(e) => handleOrarioChange(e, tabVisualizzata)}
                    style={{ marginBottom: '6px' }}
                    fullWidth />
                </Grid>
                <Grid item xs={12} sm={6} md={3} sx={{ textAlign: { xs: 'center', md: 'right' } }}>
                  <Button
                    variant="contained"
                    onClick={() => handleRemoveButtonClick(tabVisualizzata)}
                    style={{ marginBottom: '6px', backgroundColor: 'red' }}
                  >
                    Elimina
                  </Button>
                </Grid>
              </Grid>

              {/* Dialog conferma elimina tab dipendente */}
              <Dialog
                open={openDialog}
                onClose={closeRemoveDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">Conferma Rimozione</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Sei sicuro di voler rimuovere questa tabella?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={closeRemoveDialog} color="primary">
                    Annulla
                  </Button>
                  <Button onClick={() => {
                    rimuoviTabellaDipendente(selectedIndexToRemove);
                    closeRemoveDialog();
                  }} color="primary" autoFocus>
                    Conferma
                  </Button>
                </DialogActions>
              </Dialog>

              <Divider style={{ margin: '12px 0' }} />

              <TableContainer style={{ overflowX: 'auto' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Giorno</TableCell>
                      {Array.from({ length: numGiorniMese }, (_, i) => i + 1).map((giorno) => (
                        <TableCell key={giorno} style={{ color: giorniSettimana[(primoGiornoSettimana + giorno - 1) % 7] === 'Sab' || giorniSettimana[(primoGiornoSettimana + giorno - 1) % 7] === 'Dom' ? 'orange' : 'inherit', fontSize: '12px' }}>
                          {giorno}
                          <br />
                          {giorniSettimana[(primoGiornoSettimana + giorno - 1) % 7]}
                        </TableCell>
                      ))}
                      <TableCell>Totale Ore</TableCell> {/* Aggiunta della colonna Totale Ore */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Ore Lavorate</TableCell>
                      {Array.from({ length: numGiorniMese }, (_, i) => i + 1).map((giorno) => (
                        <TableCell key={giorno}>
                          <TextField
                            variant="outlined"
                            size="small"
                            placeholder=""
                            disabled={presenzeInviate}
                            value={presenze[tabVisualizzata].oreLavorate[giorno - 1] || ''}
                            onChange={(e) => handleDipendenteChange(e, tabVisualizzata, 'oreLavorate', giorno)}
                            InputProps={{ inputProps: { type: 'text', style: { padding: '4px', textAlign: 'center' } } }}
                            style={{ width: '40px' }}
                          />
                        </TableCell>
                      ))}
                      <TableCell>{sumOre(presenze[tabVisualizzata].oreLavorate)}</TableCell> {/* Mostra il totale delle ore */}
                    </TableRow>
                    <TableRow>
                      <TableCell>Notturno</TableCell>
                      {Array.from({ length: numGiorniMese }, (_, i) => i + 1).map((giorno) => (
                        <TableCell key={giorno}>
                          <TextField
                            variant="outlined"
                            size="small"
                            placeholder=""
                            disabled={presenzeInviate}
                            value={presenze[tabVisualizzata].notturno[giorno - 1] || ''}
                            onChange={(e) => handleDipendenteChange(e, tabVisualizzata, 'notturno', giorno)}
                            InputProps={{ inputProps: { type: 'text', style: { padding: '4px', textAlign: 'center' } } }}
                            style={{ width: '40px' }}
                          />
                        </TableCell>
                      ))}
                      <TableCell>{sumOre(presenze[tabVisualizzata].notturno)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Straordinari</TableCell>
                      {Array.from({ length: numGiorniMese }, (_, i) => i + 1).map((giorno) => (
                        <TableCell key={giorno}>
                          <TextField
                            variant="outlined"
                            size="small"
                            placeholder=""
                            disabled={presenzeInviate}
                            value={presenze[tabVisualizzata].straordinari[giorno - 1] || ''}
                            onChange={(e) => handleDipendenteChange(e, tabVisualizzata, 'straordinari', giorno)}
                            InputProps={{ inputProps: { type: 'text', style: { padding: '4px', textAlign: 'center' } } }}
                            style={{ width: '40px' }}
                          />
                        </TableCell>
                      ))}
                      <TableCell>{sumOre(presenze[tabVisualizzata].straordinari)}</TableCell> {/* Mostra il totale delle ore */}
                    </TableRow>
                    <TableRow>
                      <TableCell>Straordinario Notturno</TableCell>
                      {Array.from({ length: numGiorniMese }, (_, i) => i + 1).map((giorno) => (
                        <TableCell key={giorno}>
                          <TextField
                            variant="outlined"
                            size="small"
                            placeholder=""
                            disabled={presenzeInviate}
                            value={presenze[tabVisualizzata].straordinarioNotturno[giorno - 1] || ''}
                            onChange={(e) => handleDipendenteChange(e, tabVisualizzata, 'straordinarioNotturno', giorno)}
                            InputProps={{ inputProps: { type: 'text', style: { padding: '4px', textAlign: 'center' } } }}
                            style={{ width: '40px' }}
                          />
                        </TableCell>
                      ))}
                      <TableCell>{sumOre(presenze[tabVisualizzata].straordinarioNotturno)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Festivo</TableCell>
                      {Array.from({ length: numGiorniMese }, (_, i) => i + 1).map((giorno) => (
                        <TableCell key={giorno}>
                          <TextField
                            variant="outlined"
                            size="small"
                            placeholder=""
                            disabled={presenzeInviate}
                            value={presenze[tabVisualizzata].festivo[giorno - 1] || ''}
                            onChange={(e) => handleDipendenteChange(e, tabVisualizzata, 'festivo', giorno)}
                            InputProps={{ inputProps: { type: 'text', style: { padding: '4px', textAlign: 'center' } } }}
                            style={{ width: '40px' }}
                          />
                        </TableCell>
                      ))}
                      <TableCell>{sumOre(presenze[tabVisualizzata].festivo)}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>Ferie</TableCell>
                      {Array.from({ length: numGiorniMese }, (_, i) => i + 1).map((giorno) => (
                        <TableCell key={giorno}>
                          <TextField
                            variant="outlined"
                            size="small"
                            placeholder=""
                            disabled={presenzeInviate}
                            value={presenze[tabVisualizzata].ferie[giorno - 1] || ''}
                            onChange={(e) => handleDipendenteChange(e, tabVisualizzata, 'ferie', giorno)}
                            InputProps={{ inputProps: { type: 'text', style: { padding: '4px', textAlign: 'center' } } }} style={{ width: '40px' }}
                          />
                        </TableCell>
                      ))}
                      <TableCell>{sumOre(presenze[tabVisualizzata].ferie)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Festività Godute</TableCell>
                      {Array.from({ length: numGiorniMese }, (_, i) => i + 1).map((giorno) => (
                        <TableCell key={giorno}>
                          <TextField
                            variant="outlined"
                            size="small"
                            placeholder=""
                            disabled={presenzeInviate}
                            value={presenze[tabVisualizzata].festivitaGoduta[giorno - 1] || ''}
                            onChange={(e) => handleDipendenteChange(e, tabVisualizzata, 'festivitaGoduta', giorno)}
                            InputProps={{ inputProps: { type: 'text', style: { padding: '4px', textAlign: 'center' } } }}
                            style={{ width: '40px' }}
                          />
                        </TableCell>
                      ))}
                      <TableCell>{sumOre(presenze[tabVisualizzata].festivitaGoduta)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Permessi</TableCell>
                      {Array.from({ length: numGiorniMese }, (_, i) => i + 1).map((giorno) => (
                        <TableCell key={giorno}>
                          <TextField
                            variant="outlined"
                            size="small"
                            placeholder=""
                            disabled={presenzeInviate}
                            value={presenze[tabVisualizzata].permessi[giorno - 1] || ''}
                            onChange={(e) => handleDipendenteChange(e, tabVisualizzata, 'permessi', giorno)}
                            InputProps={{ inputProps: { type: 'text', style: { padding: '4px', textAlign: 'center' } } }} style={{ width: '40px' }}
                          />
                        </TableCell>
                      ))}
                      <TableCell>{sumOre(presenze[tabVisualizzata].permessi)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Assenze</TableCell>
                      {Array.from({ length: numGiorniMese }, (_, i) => i + 1).map((giorno) => (
                        <TableCell key={giorno}>
                          <TextField
                            variant="outlined"
                            size="small"
                            placeholder=""
                            disabled={presenzeInviate}
                            value={presenze[tabVisualizzata].assenze[giorno - 1] || ''}
                            onChange={(e) => handleDipendenteChange(e, tabVisualizzata, 'assenze', giorno)}
                            InputProps={{ inputProps: { type: 'text', style: { padding: '4px', textAlign: 'center' } } }}
                            style={{ width: '40px' }}
                          />
                        </TableCell>
                      ))}
                      <TableCell>{sumOre(presenze[tabVisualizzata].assenze)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Malattia</TableCell>
                      {Array.from({ length: numGiorniMese }, (_, i) => i + 1).map((giorno) => (
                        <TableCell key={giorno}>
                          <TextField
                            variant="outlined"
                            size="small"
                            placeholder=""
                            disabled={presenzeInviate}
                            value={presenze[tabVisualizzata].malattia[giorno - 1] || ''}
                            onChange={(e) => handleDipendenteChange(e, tabVisualizzata, 'malattia', giorno)}
                            InputProps={{ inputProps: { type: 'text', style: { padding: '4px', textAlign: 'center' } } }} style={{ width: '40px' }}
                          />
                        </TableCell>
                      ))}
                      <TableCell>{sumOre(presenze[tabVisualizzata].malattia)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Infortunio</TableCell>
                      {Array.from({ length: numGiorniMese }, (_, i) => i + 1).map((giorno) => (
                        <TableCell key={giorno}>
                          <TextField
                            variant="outlined"
                            size="small"
                            placeholder=""
                            disabled={presenzeInviate}
                            value={presenze[tabVisualizzata].infortunio[giorno - 1] || ''}
                            onChange={(e) => handleDipendenteChange(e, tabVisualizzata, 'infortunio', giorno)}
                            InputProps={{ inputProps: { type: 'text', style: { padding: '4px', textAlign: 'center' } } }}
                            style={{ width: '40px' }}
                          />
                        </TableCell>
                      ))}
                      <TableCell>{sumOre(presenze[tabVisualizzata].infortunio)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Maternità</TableCell>
                      {Array.from({ length: numGiorniMese }, (_, i) => i + 1).map((giorno) => (
                        <TableCell key={giorno}>
                          <TextField
                            variant="outlined"
                            size="small"
                            placeholder=""
                            disabled={presenzeInviate}
                            value={presenze[tabVisualizzata].maternita[giorno - 1] || ''}
                            onChange={(e) => handleDipendenteChange(e, tabVisualizzata, 'maternita', giorno)}
                            InputProps={{ inputProps: { type: 'text', style: { padding: '4px', textAlign: 'center' } } }}
                            style={{ width: '40px' }}
                          />
                        </TableCell>
                      ))}
                      <TableCell>{sumOre(presenze[tabVisualizzata].maternita)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Ore Viaggio</TableCell>
                      {Array.from({ length: numGiorniMese }, (_, i) => i + 1).map((giorno) => (
                        <TableCell key={giorno}>
                          <TextField
                            variant="outlined"
                            size="small"
                            placeholder=""
                            disabled={presenzeInviate}
                            value={presenze[tabVisualizzata].oreViaggio[giorno - 1] || ''}
                            onChange={(e) => handleDipendenteChange(e, tabVisualizzata, 'oreViaggio', giorno)}
                            InputProps={{ inputProps: { type: 'text', style: { padding: '4px', textAlign: 'center' } } }}
                            style={{ width: '40px' }}
                          />
                        </TableCell>
                      ))}
                      <TableCell>{sumOre(presenze[tabVisualizzata].oreViaggio)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Flessibilità</TableCell>
                      {Array.from({ length: numGiorniMese }, (_, i) => i + 1).map((giorno) => (
                        <TableCell key={giorno}>
                          <TextField
                            variant="outlined"
                            size="small"
                            placeholder=""
                            disabled={presenzeInviate}
                            value={presenze[tabVisualizzata].flessibilita[giorno - 1] || ''}
                            onChange={(e) => handleDipendenteChange(e, tabVisualizzata, 'flessibilita', giorno)}
                            InputProps={{ inputProps: { type: 'text', style: { padding: '4px', textAlign: 'center' } } }}
                            style={{ width: '40px' }}
                          />
                        </TableCell>
                      ))}
                      <TableCell>{sumOre(presenze[tabVisualizzata].flessibilita)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Altri Eventi</TableCell>
                      {Array.from({ length: numGiorniMese }, (_, i) => i + 1).map((giorno) => (
                        <TableCell key={giorno}>
                          <TextField
                            variant="outlined"
                            size="small"
                            placeholder=""
                            disabled={presenzeInviate}
                            value={presenze[tabVisualizzata].altriEventi[giorno - 1] || ''}
                            onChange={(e) => handleDipendenteChange(e, tabVisualizzata, 'altriEventi', giorno)}
                            InputProps={{ inputProps: { type: 'text', style: { padding: '4px', textAlign: 'center' } } }} style={{ width: '40px' }}
                          />
                        </TableCell>
                      ))}
                      <TableCell>{sumOre(presenze[tabVisualizzata].altriEventi)}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>Totale Ore</TableCell>
                      {Array.from({ length: numGiorniMese }, (_, i) => i + 1).map((giorno) => (
                        <TableCell key={giorno}>
                          {calcolaTotaleGiornaliero(tabVisualizzata, giorno)}
                        </TableCell>
                      ))}
                      <TableCell>{calcolaTotale(tabVisualizzata)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <TextField
                variant="outlined"
                size="small"
                label="Note"
                disabled={presenzeInviate}
                value={note[tabVisualizzata]}
                onChange={(e) => handleNoteChange(e, tabVisualizzata)}
                style={{ marginTop: '12px', width: "100%" }}
                multiline
                rows={2}
              />
            </Box>
          </Paper>
        )}

        {/* Paper Allega File */}
        <Paper elevation={3} style={{ margin: '16px', width: '90vw' }}>
          <Box p={1}>
            <Typography variant="h6" style={{ fontWeight: 'bold', textAlign: 'center' }}>
              Allega File
            </Typography>
            <Grid container spacing={2} alignItems="center">
              {/* Sfoglia e lista di nomi */}
              <Grid item xs={12} lg={4}>
                {!presenzeInviate && <Box display="flex" flexDirection="column">
                  <input
                    type="file"
                    name="docop"
                    onChange={(e) => handleMultiFileChange(e)}
                    accept=".pdf, .doc, .docx, .xls, .xlsx, .jpg, .jpeg, .png"
                    multiple
                  />
                  <Box
                    justifyContent="left"
                    width="100%"
                    marginTop={2}
                    marginBottom={2}
                  >
                    {selectedFiles.map((f, idx) => {
                      const fileURL = URL.createObjectURL(f.file);
                      return (
                        <Typography key={idx}>
                          <IconButton onClick={() => handleDeleteFile(idx)}>
                            <DeleteIcon />
                          </IconButton>
                          <a href={fileURL} target="_blank" rel="noopener noreferrer">
                            {f.name}
                          </a>
                        </Typography>
                      );
                    })}
                  </Box>
                </Box>}
              </Grid>

              {/* Pulsante centrale */}
              <Grid item xs={12} lg={4} textAlign="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDownload}
                  disabled={!uploadedFiles}
                >
                  Scarica
                </Button>
              </Grid>

              {/* Messaggio a destra */}
              <Grid item xs={12} lg={4} textAlign="center">
                {!presenzeInviate && uploadedFiles && (
                  <Typography variant="body1" color="textSecondary">
                    Documenti caricati. Per aggiungere cliccare su sfoglia di nuovo.
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>
        </Paper>

        {/* Paper per il calcolo delle ore di entrata e uscita */}
        <Paper elevation={3} style={{ margin: '16px', width: '90vw' }}>
          <Box p={2} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: '16px' }}>
              Calcolatore Ore Lavorate
            </Typography>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
              <Grid item>
                <TextField
                  label="Entrata"
                  variant="outlined"
                  placeholder="Orario di entrata (hh:mm)"
                  value={oraEntrata}
                  onChange={handleChangeOraEntrata}
                  size="small"
                />
              </Grid>

              <Grid item>
                <TextField
                  label="inizio pausa"
                  variant="outlined"
                  placeholder="Inizio pausa (hh:mm)"
                  value={oraInizioPausa}
                  onChange={handleChangeOraInizioPausa}
                  size="small"
                />
              </Grid>
              <Grid item>
                <TextField
                  label="fine pausa"
                  variant="outlined"
                  placeholder="Fine pausa (hh:mm)"
                  value={oraFinePausa}
                  onChange={handleChangeOraFinePausa}
                  size="small"
                />
              </Grid>

              <Grid item>
                <TextField
                  label="Uscita"
                  variant="outlined"
                  placeholder="Orario di uscita (hh:mm)"
                  value={oraUscita}
                  onChange={handleChangeOraUscita}
                  size="small"
                />
              </Grid>

              <Grid item>
                <TextField
                  label="Totale Ore"
                  variant="outlined"
                  placeholder="Totale Ore"
                  value={oreTotali}
                  size='small'
                  InputProps={{
                    style: { fontWeight: 'bold' }
                  }}
                  readOnly
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>

        <Grid container alignItems="center" justifyContent="center" style={{ marginTop: '8px', marginBottom: '8px' }}>
          <Button variant="contained" color="success" onClick={aggiungiTabellaDipendente}>
            Aggiungi Tabella
          </Button>
        </Grid>
      </div>)}

      {statusMessage && (
        <Paper
          variant="outlined"
          sx={{
            p: 2,
            my: 1,
            mx: 2,
            flexGrow: 1,
            backgroundColor: isError ? "#e57373" : "#81c784",
            borderColor: isError ? "#d32f2f" : "#388e3c",
            color: "black",
          }}
          role="alert"
        >
          {statusMessage}
        </Paper>
      )}

      <Grid container justifyContent="center" spacing={1}>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <Button
            sx={{ m: 0 }}
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => confirm(false)}
            disabled={presenzeInviate}
          >
            {presenzeInviate ? "Inviate" : "Salva"}
          </Button>
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={2}>
          <Button
            sx={{ m: 0 }}
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => setOpenConfirm(true)}
            disabled={presenzeInviate}
          >
            {presenzeInviate ? "Inviate" : "Invia"}
          </Button>
        </Grid>

        {/* Dialog invio presenze */}
        <Dialog
          open={openConfirm}
          onClose={() => setOpenConfirm(false)}
          aria-labelledby="alert-dialog-title1"
          aria-describedby="alert-dialog-description1"
        >
          <DialogTitle id="alert-dialog-title1">Conferma Inserimento Presenze</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description1">
              Una volta inviate, le presenze <strong style={{ color: 'red' }}>non potranno più essere modificate</strong>.
              Assicurati che le presenze di tutti i dipendenti per l'intero mese siano corrette
            </DialogContentText>
            <Typography color="error" sx={{ marginTop: '16px' }}>
              *Se vuoi solo salvare senza inviare, clicca "Annulla" qui e poi "Salva" nella schermata principale.
              Invia solo quando tutte le presenze sono complete per tutti i dipendenti.
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenConfirm(false)} color="primary">
              Annulla
            </Button>
            <Button onClick={() => confirm(true)} color="primary" autoFocus>
              Conferma
            </Button>
          </DialogActions>
        </Dialog>

      </Grid>

      <style>
        {`
    .MuiTableCell-root {
      padding: 3px;
      text-align: center;
    }
    .MuiTableCell-root:first-child {
      position: sticky;
      left: 0;
      z-index: 1;
      background-color: white; /* Colore di sfondo per la colonna fissa */
    }
  `}
      </style>
    </Box>
  );
};

export default TabellaPresenze;