import React, { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import {
  CircularProgress, TextField, Container, Typography, Button, Table, TableBody, Select, MenuItem,
  TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Grid, Divider, Stack, Tooltip,
  Dialog, DialogTitle, DialogContent, DialogActions,
} from '@mui/material';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import axios from "axios";
import utils from "../../utils";

const OpAmmAvvisiParcellaDet = () => {
  const [searchParams] = useSearchParams();
  const [avviso, setAvviso] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [esternoLoading, setEsternoLoading] = useState(false);
  const [statusMessageTicket, setStatusMessageTicket] = useState("");
  const [warningMessage, setWarningMessage] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().substring(0, 10));

  const id = searchParams.get("id");

  useEffect(() => {
    loadTicket();
  }, []);

  const loadTicket = async () => {
    try {
      const res = await axios.get(`/api/avvisoparcella?id=${id}`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error, avviso: avv } = res.data;
      if (success) {
        setAvviso({
          ...avv, altre_righe: avv.altre_righe.map(r => {
            return { ...r, prezzoUnitario: (r.prezzoUnitario / 100).toFixed(2), prezzo: (r.prezzo / 100).toFixed(2) }
          })
        });
      } else {
        setStatusMessageTicket(error);
      }
    } catch (error) {
      setStatusMessageTicket("Si è verificato un errore imprevisto sul nostro server.");
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (avviso) {
      const zeroImporti = avviso.righe.concat(avviso.altre_righe).filter(articolo => articolo.prezzo == 0);
      if (zeroImporti.length > 0) {
        setWarningMessage('Attenzione: Alcuni articoli hanno un importo pari a 0.');
      } else {
        setWarningMessage('');
      }
    }
  }, [avviso]);

  const handleAddNewArticolo = () => {
    const newArticolo = { id: "", descrizione: "", quantita: 0, prezzoUnitario: '0', iva: 2200, prezzo: "-" };
    setAvviso({ ...avviso, altre_righe: [...avviso.altre_righe, newArticolo] });
  };

  const handleRemoveArticolo = (indexToRemove) => {
    const updatedArticoli = avviso.altre_righe.filter((_, index) => index !== indexToRemove);
    setAvviso({ ...avviso, altre_righe: updatedArticoli });
  };

  const handleSalvaAvviso = async () => {
    setStatusMessageTicket("");
    setIsLoading(true);
    try {
      const res = await axios.post(
        `/api/updateavvisoparcella`,
        {
          id, righe: avviso.altre_righe.map(r => {
            return { ...r, prezzoUnitario: Math.round(Number(r.prezzoUnitario) * 100), prezzo: Math.round(Number(r.prezzo) * 100) }
          })
        },
        {
          headers: utils.getAuthHeaders(),
        }
      );
      const { success, error } = res.data;
      if (success) {
        return window.location.reload();
      } else {
        setStatusMessageTicket(error);
      }
    } catch (error) {
      setStatusMessageTicket(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleConferma = async () => {
    setStatusMessageTicket("");
    setIsLoading(true);
    try {
      const res = await axios.post(
        `/api/confirmavvisoparcella`,
        { id },
        {
          headers: utils.getAuthHeaders(),
        }
      );
      const { success, error } = res.data;
      if (success) {
        return window.location.reload();
      } else {
        setStatusMessageTicket(error);
      }
    } catch (error) {
      setStatusMessageTicket(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleElimina = async () => {
    setStatusMessageTicket("");
    setIsLoading(true);
    try {
      const res = await axios.post(
        `/api/deleteavvisoparcella`,
        { id },
        {
          headers: utils.getAuthHeaders(),
        }
      );
      const { success, error } = res.data;
      if (success) {
        return window.location.href = '/op/amministrazione/avvisiparcella';
      } else {
        setStatusMessageTicket(error);
      }
    } catch (error) {
      setStatusMessageTicket(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleSegnaPagato = async () => {
    setStatusMessageTicket("");
    setIsLoading(true);
    try {
      const res = await axios.post(
        `/api/payavvisoparcella`,
        { id, data: selectedDate },
        {
          headers: utils.getAuthHeaders(),
        }
      );
      const { success, error } = res.data;
      if (success) {
        return window.location.reload();
      } else {
        setStatusMessageTicket(error);
      }
    } catch (error) {
      setStatusMessageTicket(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleSalvaInfo = async () => {
    setStatusMessageTicket("");
    setIsLoading(true);
    try {
      // Esegui l'azione per salvare le informazioni aggiuntive
      const res = await axios.post(
        `/api/updateavvisoinfo`,
        { id, tipo_pagamento: avviso.tipo_pagamento, numero_cp: avviso.numero_cp, periodo: avviso.periodo, note: avviso.note },
        {
          headers: utils.getAuthHeaders(),
        }
      );
      const { success, error } = res.data;
      if (success) {
        return window.location.reload();
      } else {
        setStatusMessageTicket(error);
      }
    } catch (error) {
      setStatusMessageTicket(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return <Container>
      <Stack sx={{ my: 5 }} alignItems="center">
        <CircularProgress disableShrink />
      </Stack>
    </Container>
  }

  const handleFileUpload = async (e) => {
    e.preventDefault();
    setEsternoLoading(true);
    try {
      const file = e.target.files[0];
      if (!file) return;

      const payload = new FormData();

      const json = {
        id,
        doc: file.name
      };

      payload.append("doc", file);
      payload.append("data", JSON.stringify(json));

      const res = await axios.post("/api/caricaavvisopdf", payload, {
        headers: {
          ...utils.getAuthHeaders(),
          "Content-Type": "multipart/form-data",
        },
      });
      const { success, error, docId } = res.data;
      if (success) {
        setAvviso({ ...avviso, doc_esterno: docId });
      } else {
        setStatusMessageTicket(error);
      }
    } catch (error) {
      setStatusMessageTicket(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setEsternoLoading(false);
  }

  const downloadDoc = async (docId) => {
    try {
      const response = await axios.get(`/api/scarica?id=${docId}`, {
        headers: utils.getAuthHeaders(),
        responseType: "blob",
      });

      const contentDisposition = response.headers["content-disposition"];
      const filename = contentDisposition
        ? contentDisposition.split(";")[1].split("filename=")[1].trim()
        : "documento";

      const fileBlob = new Blob([response.data]);
      const fileUrl = URL.createObjectURL(fileBlob);

      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = filename;
      link.click();
      URL.revokeObjectURL(fileUrl);
    } catch (error) {
      setStatusMessageTicket("File non trovato.");
    }
  };

  return (
    <Container>
      <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: '8px', marginTop: '8px' }}>
        <Grid item>
          <Typography variant="h4" align="center" sx={{ mt: 2 }}>
            Dettaglio Avviso di Parcella
          </Typography>
        </Grid>
        <Grid item>
          {avviso.esterno && <Paper style={{ padding: '4px', paddingTop: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '200px', height: '60px', marginTop: '8px', backgroundColor: '#FFE6E6' }}>
            <Typography variant="h6" align="center" style={{ marginBottom: '1px', fontWeight: 'bold' }}>
              Avviso Esterno
            </Typography>
            <input
              type="file"
              style={{ display: 'none' }}
              id="file-upload"
              onChange={handleFileUpload}
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip title="Carica">
                <label htmlFor="file-upload">
                  <IconButton component="span" style={{ color: 'black', marginTop: 0 }}>
                    <CloudUploadIcon />
                  </IconButton>
                </label>
              </Tooltip>
              {esternoLoading ? <CircularProgress sx={{ display: 'block', margin: '0 auto' }} /> : <div />}
              {avviso.doc_esterno && (
                <Tooltip title="Scarica">
                  <IconButton onClick={() => downloadDoc(avviso.doc_esterno)} style={{ color: 'green', marginLeft: '8px' }}>
                    <CloudDownloadIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </Paper>}
          {/*<IconButton onClick={printPDF} color="primary">
        <PrintOutlinedIcon />
      </IconButton>*/}
        </Grid>
      </Grid>

      <Button variant="contained" onClick={() => window.location.href = '/op/amministrazione/avvisiparcella'} style={{ marginBottom: '8px' }}>
        Torna Indietro
      </Button>

      {avviso && (
        <div>
          <Divider style={{ margin: '16px 0' }} />

          <Grid container spacing={3}>
            {/* Prima colonna - Dettagli Avviso */}
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom>Dettagli Avviso</Typography>
              <Typography style={{ marginBottom: '8px' }}>ID Avviso di Parcella: {avviso.id}</Typography>
              <Typography style={{ marginBottom: '8px' }}>Data Documento: {utils.revDate(avviso.data_documento) || "NON CONFERMATO"}</Typography>
              <Typography style={{ marginBottom: '8px' }}>Data Scadenza: {utils.revDate(avviso.data_scadenza) || "NON DEFINITA"}</Typography>
              <Typography style={{ marginBottom: '8px' }}>Data Pagamento: {utils.revDate(avviso.data_pagamento) || "NON PAGATO"}</Typography>
            </Grid>

            {/* Seconda colonna - Azienda e P.IVA */}
            <Grid item xs={12} md={4}>
              <Typography variant="h6" style={{ marginBottom: '8px' }}>{avviso.azienda}</Typography>
              <Typography style={{ marginBottom: '8px' }}>{avviso.piva}</Typography>
            </Grid>

            {/* Terza colonna - TextField */}
            <Grid item xs={12} md={4} container direction="column" justifyContent="flex-end" alignItems="flex-end">
              <TextField
                label="Tipo Pagamento"
                variant="standard"
                size="small"
                value={avviso.tipo_pagamento || ""}
                onChange={(e) => setAvviso({ ...avviso, tipo_pagamento: e.target.value })}
                fullWidth
                style={{ marginBottom: '4px' }}
              />
              <TextField
                label="Numero Centro Paghe"
                variant="standard"
                size="small"
                value={avviso.numero_cp || ""}
                onChange={(e) => setAvviso({ ...avviso, numero_cp: e.target.value })}
                fullWidth
                style={{ marginBottom: '4px' }}
              />
              <TextField
                label="Periodo"
                variant="standard"
                size="small"
                value={avviso.periodo || ""}
                onChange={(e) => setAvviso({ ...avviso, periodo: e.target.value })}
                fullWidth
                style={{ marginBottom: '4px' }}
              />
              <TextField
                label="Note"
                variant="standard"
                size="small"
                multiline
                rows={3}
                value={avviso.note || ""}
                onChange={(e) => setAvviso({ ...avviso, note: e.target.value })}
                fullWidth
              />
            </Grid>
          </Grid>
        </div>
      )}

      <Divider style={{ margin: '16px 0' }} />

      {avviso && (
        <TableContainer component={Paper} style={{ marginBottom: '16px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Articolo</TableCell>
                <TableCell>Descrizione</TableCell>
                <TableCell>Quantità</TableCell>
                <TableCell>Prezzo Unitario</TableCell>
                <TableCell>Importo</TableCell>
                <TableCell>IVA</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {avviso.righe.map((articolo, index) => (
                <TableRow key={index}>
                  <TableCell>{articolo.id}</TableCell>
                  <TableCell>{articolo.descrizione}</TableCell>
                  <TableCell>{articolo.unita}</TableCell>
                  <TableCell>{(Number(articolo.prezzoUnitario) / 100).toFixed(2)} €</TableCell>
                  <TableCell>{(Number(articolo.prezzo) / 100).toFixed(2)} €</TableCell>
                  <TableCell>{articolo.iva}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>)}

      <Divider style={{ margin: '16px 0' }} />

      <Typography variant="h6" gutterBottom>
        Aggiungi Servizi
      </Typography>

      {avviso && <TableContainer component={Paper} style={{ marginBottom: '16px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Articolo</TableCell>
              <TableCell>Descrizione</TableCell>
              <TableCell>Quantità</TableCell>
              <TableCell>Prezzo Unitario</TableCell>
              <TableCell>Importo</TableCell>
              <TableCell>IVA</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {avviso.altre_righe.map((articolo, index) => (
              <TableRow key={index}>
                <TableCell>
                  <TextField
                    type="text"
                    value={articolo.id}
                    onChange={(e) => {
                      const updatedArticoli = [...avviso.altre_righe];
                      updatedArticoli[index].id = e.target.value;
                      setAvviso({ ...avviso, altre_righe: updatedArticoli });
                    }}
                    variant="outlined"
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    type="text"
                    value={articolo.descrizione}
                    onChange={(e) => {
                      const updatedArticoli = [...avviso.altre_righe];
                      updatedArticoli[index].descrizione = e.target.value;
                      setAvviso({ ...avviso, altre_righe: updatedArticoli });
                    }}
                    variant="outlined"
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    value={articolo.unita}
                    onChange={(e) => {
                      const updatedArticoli = [...avviso.altre_righe];
                      updatedArticoli[index].unita = e.target.value;
                      const prezzoTot = Number(updatedArticoli[index].prezzoUnitario.replace(',', '.')) ?
                        (e.target.value * Number(updatedArticoli[index].prezzoUnitario.replace(',', '.'))).toFixed(2) :
                        '-';
                      updatedArticoli[index].prezzo = prezzoTot;
                      setAvviso({ ...avviso, altre_righe: updatedArticoli });
                    }}
                    variant="outlined"
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    type="text"
                    value={articolo.prezzoUnitario}
                    onChange={(e) => {
                      const updatedArticoli = [...avviso.altre_righe];
                      updatedArticoli[index].prezzoUnitario = e.target.value.replace(',', '.');
                      const prezzoTot = Number(e.target.value.replace(',', '.')) ?
                        (updatedArticoli[index].unita * Number(e.target.value.replace(',', '.'))).toFixed(2) :
                        '-';
                      updatedArticoli[index].prezzo = prezzoTot;
                      setAvviso({ ...avviso, altre_righe: updatedArticoli });
                    }}
                    variant="outlined"
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  {articolo.prezzo}
                </TableCell>
                <TableCell>
                  <Select
                    value={articolo.iva}
                    onChange={(e) => {
                      const updatedArticoli = [...avviso.altre_righe];
                      updatedArticoli[index].iva = e.target.value;
                      setAvviso({ ...avviso, altre_righe: updatedArticoli });
                    }}
                    variant="outlined"
                    size="small"
                  >
                    <MenuItem value={2200}>2200</MenuItem>
                    <MenuItem value={0}>0000</MenuItem>
                  </Select>
                </TableCell>
                <TableCell>
                  {/*<IconButton
                    color="primary"
                    style={{ marginRight: '8px' }}
                    onClick={() => handleModifyArticolo(index)}
                  >
                    <EditIcon />
                  </IconButton>*/}
                  <IconButton
                    onClick={() => handleRemoveArticolo(index)}
                    disabled={avviso.confermato}
                    sx={{ color: 'red' }}
                  >
                    <ClearIcon />
                  </IconButton>

                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>}

      <Button disabled={avviso && avviso.confermato} variant="contained" style={{ backgroundColor: '#FFA500', color: 'white', marginBottom: '16px' }} onClick={handleAddNewArticolo}>
        Aggiungi
      </Button>

      <Divider style={{ margin: '16px 0' }} />

      <Typography variant="h6" gutterBottom>
        Totale
      </Typography>

      {avviso && <TableContainer component={Paper} style={{ marginBottom: '16px' }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <Grid container>
                  <Grid item xs={6}><strong>Totale Imponibile</strong></Grid>
                  <Grid item xs={6}>{(Number(avviso.tot_imponibile) / 100).toFixed(2)}</Grid>
                </Grid>
              </TableCell>
              <TableCell>
                <Grid container>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={6}></Grid>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Grid container>
                  <Grid item xs={6}><strong>CPR</strong></Grid>
                  <Grid item xs={6}>{(Number(avviso.cpr) / 100).toFixed(2)}</Grid>
                </Grid>
              </TableCell>
              <TableCell>
                <Grid container>
                  <Grid item xs={6}><strong>Tot imponibile + CPR</strong></Grid>
                  <Grid item xs={6}>{(Number(avviso.tot_imponibile_cpr) / 100).toFixed(2)}</Grid>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Grid container>
                  <Grid item xs={6}><strong>Tot Imposta 22%</strong></Grid>
                  <Grid item xs={6}>{(Number(avviso.tot_imposta) / 100).toFixed(2)}</Grid>
                </Grid>
              </TableCell>
              <TableCell>
                <Grid container>
                  <Grid item xs={6}><strong>Tot Ritenuta D acconto 20%</strong></Grid>
                  <Grid item xs={6}>{(Number(avviso.tot_ritenuta) / 100).toFixed(2)}</Grid>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Grid container>
                  <Grid item xs={6}><strong>Tot Documento</strong></Grid>
                  <Grid item xs={6}>{(Number(avviso.tot_documento) / 100).toFixed(2)}</Grid>
                </Grid>
              </TableCell>
              <TableCell>
                <Grid container>
                  <Grid item xs={6}><strong>Tot da pagare</strong></Grid>
                  <Grid item xs={6}>{(Number(avviso.tot_a_pagare) / 100).toFixed(2)}</Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>}

      <Divider style={{ margin: '16px 0' }} />

      {warningMessage && (
        <Paper
          variant="outlined"
          sx={{
            p: 2,
            mt: 2,
            mb: 2,
            backgroundColor: "#e5c773",
            borderColor: "#d3a72f",
            color: "black",
          }}
          role="alert"
        >
          {warningMessage}
        </Paper>
      )}
      {statusMessageTicket && (
        <Paper
          variant="outlined"
          sx={{
            p: 2,
            mt: 2,
            mb: 2,
            backgroundColor: "#e57373",
            borderColor: "#d32f2f",
            color: "black",
          }}
          role="alert"
        >
          {statusMessageTicket}
        </Paper>
      )}

      {avviso && <div style={{ marginBottom: '16px', marginTop: '16px' }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={4} md={2.4}>
            <Button
              variant="contained"
              color="primary"
              style={{ width: '100%' }}
              onClick={handleSalvaAvviso}
              disabled={avviso.confermato}
            >
              Salva Righe
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2.4}>
            <Button
              variant="contained"
              color="secondary"
              style={{ width: '100%' }}
              onClick={handleSalvaInfo}
            >
              Salva Info
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2.4}>
            <Button
              variant="contained"
              color="success"
              style={{ width: '100%' }}
              onClick={handleConferma}
              disabled={avviso.confermato}
            >
              Conferma
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2.4}>
            <Button
              variant="contained"
              color="error"
              style={{ width: '100%' }}
              onClick={handleElimina}
              disabled={avviso.confermato}
            >
              Elimina
            </Button>
          </Grid>

          {/* Dialog per inserimento data Pagato*/}
          <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
            <DialogTitle>Segna come Pagato</DialogTitle>
            <DialogContent>
              <TextField
                label="Data di Pagamento"
                type="date"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
                fullWidth
                required
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDialog(false)} color="primary">
                Annulla
              </Button>
              <Button onClick={handleSegnaPagato} color="primary">
                Conferma
              </Button>
            </DialogActions>
          </Dialog>

          <Grid item xs={12} sm={4} md={2.4}>
            <Button
              variant="contained"
              color="primary"
              style={{ width: '100%' }}
              onClick={() => setOpenDialog(true)}
              disabled={!avviso.confermato || avviso.pagato}
            >
              Pagato
            </Button>
          </Grid>
        </Grid>

      </div>}
    </Container>
  );
};

export default OpAmmAvvisiParcellaDet;
