import React, { useState, useEffect } from "react";
import {
  TextField, Button, Input, Box, Container, Typography, Paper, CircularProgress, MenuItem,
  FormControl, InputLabel, Select, Autocomplete,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import JSZip from 'jszip';
import axios from "axios";
import utils from "../../utils";
import ContrSelectDipendente from './ContrSelectDipendente';

const ContrTrasform = ({ op }) => {
  const [formValues, setFormValues] = useState({
    nome: "",
    cognome: "",
    dataNascita: "",
    codiceFiscale: "",
    dataAssunzione: null,
    dataEffettoTrasformazione: null,
    tipoTrasformazione: "",
    tipoOrario: "",
    mansioneLivello: "",
    oreSettimanali: "",
    distribuzioneOrario: "",
    note: "",
    cartaIdentita: null,
    codiceFiscaleUpload: null,
    permessoSoggiorno: null,
    certificatoResidenza: null,
    docUnilav: null,
  });

  const [statusMessage, setStatusMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [aziende, setAziende] = useState([]);
  const [aziendaId, setAziendaId] = useState(0);
  const [isMaster, setIsMaster] = useState(false);
  const [operatori, setOperatori] = useState([{ id: 0, nome: 'Caricamento...' }]);

  // Effetto per il caricamento dei dati iniziali
  useEffect(() => {
    loadData();
    if (op) {
      loadAziendeEOp();
    }
  }, []);

  // Funzione per il caricamento dei dati iniziali
  const loadData = async () => {
    try {
      const res = await axios.get("/api/me", {
        headers: utils.getAuthHeaders(),
        validateStatus: () => true,
      });

      if (res.status === 200) {
        const { success, error, me } = res.data;
        if (success && op && !me.op) {
          window.location.href = "/cli";
          return;
        } if (success) {
          setIsMaster(me.is_master);
          if (me.is_master) {
            loadSottoAziende();
          }
          setLoading(false);
          return; // Rimani sulla pagina
        } else if (error) {
          setStatusMessage(error);
        }
      }
    } catch (error) {
      setStatusMessage("Si è verificato un errore imprevisto sul nostro server.");
    }

    setLoading(false);

    if (process.env.REACT_APP_ENV !== "test") {
      window.location.href = "/login";
    }
  };

  const loadAziendeEOp = async () => {
    try {
      const res = await axios.get(`/api/aziendeeop`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setAziende(res.data.aziende);
        setOperatori(res.data.operatori);
      } else {
        setStatusMessage(error);
      }
    } catch (error) {
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
  }

  const loadSottoAziende = async () => {
    try {
      const res = await axios.get(`/api/sottoaziende`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setAziende(res.data.aziende);
      } else {
        setStatusMessage(error);
      }
    } catch (error) {
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
  }

  // Funzione per la gestione dei cambiamenti negli input
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  // Funzione per la gestione dei cambiamenti nelle date
  const handleDateChange = (name, newValue) => {
    if (!newValue) {
      setFormValues({
        ...formValues,
        [name]: null,
      });
      return;
    }
    if (newValue.$d.toString() == 'Invalid Date') {
      return;
    }
    const day = newValue.$D.toString().padStart(2, "0");
    const month = (newValue.$M + 1).toString().padStart(2, "0");
    const year = newValue.$y;
    const formattedDate = `${year}-${month}-${day}`;
    setFormValues({
      ...formValues,
      [name]: formattedDate,
    });
  };

  // Funzione per la gestione dei file
  const handleFileChange = (e, name) => {
    const file = e.target.files[0];
    setFormValues({
      ...formValues,
      [name]: file,
    });
  };

  const handleMultiFileChange = (e, name) => {
    const files = e.target.files;

    if (files.length == 1) {
      setFormValues({
        ...formValues,
        [name]: { name: files[0].name, file: files[0] },
      });
      return;
    }

    const zip = new JSZip();

    // Aggiungi tutti i file al contenuto del file zip
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      zip.file(file.name, file);
    }

    // Crea un blob zip contenente tutti i file
    zip.generateAsync({ type: 'blob' }).then((zippedBlob) => {
      setFormValues({
        ...formValues,
        [name]: { name: `${name}.zip`, file: zippedBlob },
      });
    });
  }

  // Funzione per la gestione del cambio nei menu a tendina
  const handleSelectChange = (name, value) => {
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  // Funzione per la gestione dell'invio del modulo
  const handleSubmit = async (e) => {
    setStatusMessage("");
    e.preventDefault();

    if (!formValues.dataEffettoTrasformazione) {
      setStatusMessage("Inserire la data di effetto trasformazione");
      return;
    }

    // Condizione per rendere il campo "Ore Totali Aggiornate" non obbligatorio
    const isOreTotaliRequired = !(
      formValues.tipoTrasformazione === "Da Determinato A Indeterminato" &&
      formValues.tipoOrario === "Invariato"
    );

    if (isOreTotaliRequired && !formValues.oreSettimanali) {
      setStatusMessage("Inserire il numero di ore settimanali aggiornate");
      return;
    }

    // Creazione dell'oggetto JSON
    const json = {
      tipo: "trasformazione",
      nome: formValues.nome,
      cognome: formValues.cognome,
      cod_fiscale: formValues.codiceFiscale,
      data_nascita: formValues.dataNascita,
      data_assunzione: formValues.dataAssunzione,
      data_effetto_trasformazione: formValues.dataEffettoTrasformazione,
      tipo_trasformazione: formValues.tipoTrasformazione,
      tipo_orario: formValues.tipoOrario,
      mansione_livello: formValues.mansioneLivello,
      ore_settimanali: formValues.oreSettimanali,
      distribuzione_orario: formValues.distribuzioneOrario,
      note: formValues.note,

      doc_carta: formValues.cartaIdentita
        ? formValues.cartaIdentita.name
        : null,
      doc_cf: formValues.codiceFiscaleUpload
        ? formValues.codiceFiscaleUpload.name
        : null,
      doc_permesso: formValues.permessoSoggiorno
        ? formValues.permessoSoggiorno.name
        : null,
      doc_residenza: formValues.certificatoResidenza
        ? formValues.certificatoResidenza.name
        : null,
      doc_unilav: formValues.docUnilav ? formValues.docUnilav.name : null,

      azienda: formValues.azienda,
      in_carico: formValues.inCarico,
    };

    const payload = new FormData();

    if (formValues.cartaIdentita) {
      payload.append("carta", formValues.cartaIdentita.file);
    }
    if (formValues.codiceFiscaleUpload) {
      payload.append("cf", formValues.codiceFiscaleUpload.file);
    }
    if (formValues.permessoSoggiorno) {
      payload.append("ps", formValues.permessoSoggiorno.file);
    }
    if (formValues.certificatoResidenza) {
      payload.append("cr", formValues.certificatoResidenza.file);
    }
    if (formValues.docUnilav) {
      payload.append("ul", formValues.docUnilav.file);
    }

    payload.append("data", JSON.stringify(json));

    try {
      setLoading(true);
      // Effettua la richiesta POST al server
      const res = await axios.post("/api/createticket", payload, {
        headers: {
          ...utils.getAuthHeaders(),
          "Content-Type": "multipart/form-data",
        },
      });
      const { success, error } = res.data;
      setLoading(false);
      if (success) {
        if (op) {
          window.location.href = "/success?backto=%2Fop%2Fcontrattualistica";
        } else {
          window.location.href = "/success";
        }
      } else {
        // Altrimenti, mostra un messaggio di errore
        setStatusMessage(error);
        return;
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 413) {
        // Errore se il file è troppo grande
        setStatusMessage(
          "I files sono troppo grandi. Il limite massimo è di 100 MB."
        );
      } else {
        // Altro errore imprevisto
        setStatusMessage(
          "Si è verificato un errore imprevisto sul nostro server."
        );
      }
    }
  };

  return (
    <Container>
      <Typography variant="h4" align="center" mt={3}>
        Pratica di Trasformazione
      </Typography>

      {/* Selettore Dipendente */}
      <ContrSelectDipendente setFormValues={setFormValues} aziendaId={aziendaId} />

      <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
        <form className="trasform-form" onSubmit={handleSubmit}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
              display="grid"
              gap={2}
              borderRadius={5}
              border="1px solid #ccc"
              padding={3}
              marginX="auto"
              gridTemplateColumns={{ xs: "1fr", md: "1fr 1fr" }}
            >
              <TextField
                label="Nome"
                type="text"
                name="nome"
                value={formValues.nome}
                onChange={handleInputChange}
                required
              />
              <TextField
                label="Cognome"
                type="text"
                name="cognome"
                value={formValues.cognome}
                onChange={handleInputChange}
                required
              />
              <TextField
                label="Data di Nascita"
                type="text"
                name="dataNascita"
                value={formValues.dataNascita}
                onChange={handleInputChange}
                required
              />
              <TextField
                label="Codice Fiscale"
                type="text"
                name="codiceFiscale"
                value={formValues.codiceFiscale}
                onChange={handleInputChange}
                required
              />
              <DatePicker
                label="Data Assunzione"
                format="DD/MM/YYYY"
                value={formValues.dataAssunzione}
                onChange={(newValue) =>
                  handleDateChange("dataAssunzione", newValue)
                }
                required
              />
              <DatePicker
                label="Data Effetto Trasformazione *"
                format="DD/MM/YYYY"
                value={formValues.dataEffettoTrasformazione}
                onChange={(newValue) =>
                  handleDateChange("dataEffettoTrasformazione", newValue)
                }
                required
              />
              <TextField
                label="Mansione e Livello"
                type="text"
                name="mansioneLivello"
                value={formValues.mansioneLivello}
                onChange={handleInputChange}
                required
              />
              <TextField
                select
                label="Tipo Trasformazione"
                name="tipoTrasformazione"
                value={formValues.tipoTrasformazione}
                onChange={(e) => handleSelectChange("tipoTrasformazione", e.target.value)}
                required
              >
                <MenuItem value="Da Determinato A Indeterminato">
                  Da Determinato a Indeterminato
                </MenuItem>
                <MenuItem value="Da Part-Time A Full-Time">
                  Da Part-Time a Full-Time
                </MenuItem>
                <MenuItem value="Da Full-Time A Part-Time">
                  Da Full-Time a Part-Time
                </MenuItem>
                <MenuItem value="Invariato">
                  Invariato
                </MenuItem>
              </TextField>

              {formValues.tipoTrasformazione === 'Da Full-Time A Part-Time' &&
                <TextField
                  select
                  label="Tipo di Orario"
                  name="tipoOrario"
                  value={formValues.tipoOrario}
                  onChange={(e) => handleSelectChange("tipoOrario", e.target.value)}
                  required
                >
                  <MenuItem value="Part Time Orizzontale">Part-Time Orizzontale</MenuItem>
                  <MenuItem value="Part Time Verticale">Part-Time Verticale</MenuItem>
                  <MenuItem value="Invariato">Invariato</MenuItem>
                </TextField>
              }
              {formValues.tipoTrasformazione === 'Invariato' &&
                <TextField
                  select
                  label="Tipo di Orario"
                  name="tipoOrario"
                  value={formValues.tipoOrario}
                  onChange={(e) => handleSelectChange("tipoOrario", e.target.value)}
                  required
                >
                  <MenuItem value="Da Parziale A Tempo Pieno">Da Parziale a Tempo Pieno</MenuItem>
                  <MenuItem value="Da Tempo Pieno A Parziale">Da Tempo Pieno a Parziale</MenuItem>
                  <MenuItem value="Part Time Orizzontale">Part-Time Orizzontale</MenuItem>
                  <MenuItem value="Part Time Verticale">Part-Time Verticale</MenuItem>
                  <MenuItem value="Invariato">Invariato</MenuItem>
                </TextField>
              }
              {!['Da Full-Time A Part-Time', 'Invariato'].includes(formValues.tipoTrasformazione) &&
                <TextField
                  select
                  label="Tipo di Orario"
                  name="tipoOrario"
                  value={formValues.tipoOrario}
                  onChange={(e) => handleSelectChange("tipoOrario", e.target.value)}
                  required
                  disabled={formValues.tipoTrasformazione === "Da Part-Time A Full-Time"}
                >
                  <MenuItem value="Da Parziale A Tempo Pieno">Da Parziale a Tempo Pieno</MenuItem>
                  <MenuItem value="Da Tempo Pieno A Parziale">Da Tempo Pieno a Parziale</MenuItem>
                  <MenuItem value="Invariato">Invariato</MenuItem>
                </TextField>
              }

              <TextField
                label="Ore Totali Aggiornate"
                type="number"
                name="oreSettimanali"
                value={formValues.oreSettimanali}
                onChange={handleInputChange}
                required={
                  !(
                    formValues.tipoTrasformazione === "Da Determinato A Indeterminato" &&
                    formValues.tipoOrario === "Invariato"
                  )
                }
                inputProps={{
                  min: 1,
                  step: 1,
                }}
                disabled={formValues.tipoTrasformazione === "Da Part-Time A Full-Time"}
              />

              <TextField
                label="Distribuzione Orario"
                name="distribuzioneOrario"
                value={formValues.distribuzioneOrario}
                onChange={handleInputChange}
                multiline
                rows={7} // Imposta il numero di righe necessario
                placeholder="Lun: 09:00 - 17:00&#10;Mar: 09:00 - 17:00&#10;Mer: 09:00 - 17:00&#10;Gio: 09:00 - 17:00&#10;Ven: 09:00 - 17:00&#10;Sab: 09:00 - 17:00&#10;Dom: 09:00 - 17:00&#10;"
                required
              />
              <TextField
                label="Note (totale ore, stipendio, altro)"
                name="note"
                value={formValues.note}
                onChange={handleInputChange}
                multiline
                rows={7}
              />

              <h2>Documenti</h2>
              <div></div>

              <label htmlFor="cartaIdentita">
                Carta d'Identità <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="file"
                name="cartaIdentita"
                onChange={(e) => handleMultiFileChange(e, "cartaIdentita")}
                accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                multiple
              />
              <label htmlFor="codiceFiscaleUpload">
                Codice Fiscale <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="file"
                name="codiceFiscaleUpload"
                onChange={(e) => handleMultiFileChange(e, "codiceFiscaleUpload")}
                accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                multiple
              />
              <label htmlFor="permessoSoggiorno">
                Permesso di Soggiorno (se extra UE){" "}
                <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="file"
                name="permessoSoggiorno"
                onChange={(e) => handleMultiFileChange(e, "permessoSoggiorno")}
                accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                multiple
              />
              <label htmlFor="certificatoResidenza">
                Certificato di Residenza (se extra UE){" "}
                <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="file"
                name="certificatoResidenza"
                onChange={(e) => handleMultiFileChange(e, "certificatoResidenza")}
                accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                multiple
              />
              <label htmlFor="unilav">
                Documento Unilav <span style={{ color: "red" }}>*</span>
              </label>{" "}
              <input
                type="file"
                name="docUnilav"
                onChange={(e) => handleMultiFileChange(e, "docUnilav")}
                accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                multiple
              />

              {op ? (<h2>Azienda e incaricato:</h2>) : null}
              {isMaster ? (<h2>Azienda:</h2>) : null}
              {(op || isMaster) ? (<Typography></Typography>) : null}
              {(op || isMaster) ?
                (
                  <FormControl>
                    <Autocomplete
                      id="azienda"
                      value={formValues.azienda}
                      onChange={(event, newValue) => {
                        setFormValues({
                          ...formValues,
                          azienda: newValue,
                        });
                        setAziendaId(newValue);
                      }}
                      options={aziende.map((az) => az.id)}
                      getOptionLabel={(id) => {
                        const a = aziende.filter(az => az.id == id)[0];
                        return `${a.nome} (${a.id})`;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Azienda"
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                ) : null
              }
              {op ?
                (
                  <FormControl>
                    <InputLabel id="select-op-label">Operatore</InputLabel>
                    <Select
                      labelId="inCarico-label"
                      id="inCarico"
                      name="inCarico"
                      value={formValues.inCarico}
                      onChange={handleSelectChange}
                      required
                      label="Operatore"
                    >
                      {operatori.map((ope) => (
                        <MenuItem key={ope.id} value={ope.id}>{ope.nome}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : null
              }
              {isMaster ? (<Typography></Typography>) : null}
            </Box>

            <Typography textAlign="center" color="red" my={1}>
              *Allegare questi documenti, fronte e retro, se l'assunzione non è stata fatta da questo Studio
            </Typography>
            <Box
              justifyContent={"center"}
              alignItems={"center"}
              display={"flex"}
              flexDirection="column"
              px={2}
            >
              {statusMessage && (
                <Paper
                  variant="outlined"
                  sx={{
                    p: 2,
                    mt: 2,
                    flexGrow: 1,
                    backgroundColor: "#e57373",
                    borderColor: "#d32f2f",
                    color: "black",
                    width: "100%",
                  }}
                  role="alert"
                >
                  {statusMessage}
                </Paper>
              )}
              <Box
                justifyContent="center"
                alignItems="center"
                display="flex"
                flexDirection="column"
                px={2}
              >
                {loading ? (
                  <CircularProgress sx={{ mt: 2 }} />
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ mt: 2, px: 8 }}
                  >
                    Invia
                  </Button>
                )}
              </Box>
            </Box>
          </LocalizationProvider>
        </form>
      </Box>
    </Container>
  );
};

export default ContrTrasform;