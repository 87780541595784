import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import utils from "../../utils";
import { Container, Box, Typography, IconButton, Drawer, Divider, List, ListItem, ListItemText, Tooltip, Badge } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import TimelineIcon from '@mui/icons-material/Timeline';
import FeedbackIcon from '@mui/icons-material/Feedback';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import NewReleasesIcon from '@mui/icons-material/NewReleases';

const VISIBILI_A_TUTTI = ['prenotasala', 'guida'];

function Op() {

  const [visible, setVisible] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false); // Stato per controllare se l'utente è un amministratore
  const [name, setName] = useState("");
  const [feedbackDrawerOpen, setFeedbackDrawerOpen] = useState(false); // Stato per il drawer dei feedback
  const [feedbacks, setFeedbacks] = useState([]);
  const [nuoviFeedbacks, setNuoviFeedbacks] = useState(0);

  useEffect(() => {
    loadData();
    loadFeedbacks();
  }, []);

  const loadData = async () => {
    try {
      const res = await axios.get("/api/me", {
        headers: utils.getAuthHeaders(),
        validateStatus: () => true,
      });
      if (res.status === 200) {
        const { success, me } = res.data;
        if (success && !me.op) {
          window.location.href = "/cli";
          return;
        }
        if (success) {
          setVisible(me.aree.split(',').concat(...VISIBILI_A_TUTTI));
          setIsAdmin(me.aree.split(',').includes('amministrazione'));
          setName(me.nome);
          return; // stay on the page
        }
      }
    } catch (_) { }
    if (process.env.REACT_APP_ENV !== "test") {
      window.location.href = "/login";
    }
  };

  const loadFeedbacks = async () => {
    try {
      const res = await axios.get("/api/feedbacks", {
        headers: utils.getAuthHeaders(),
        validateStatus: () => true,
      });
      if (res.status === 200) {
        const { success, error } = res.data;
        if (success) {
          setFeedbacks(res.data.feedbacks);
          setNuoviFeedbacks(res.data.count);
        } else {
          console.error(error);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const links = [
    { url: 'contrattualistica', name: 'Contrattualistica' },
    { url: 'amministrazione', name: 'Amministrazione' },
    { url: 'paghe', name: 'Paghe' },
    { url: 'enti', name: 'Enti' },
    { url: 'prenotasala', name: 'Sala Riunioni' },
    // { url: 'prenotacba', name: 'Prenotazioni CBA' },
    { url: 'costi', name: 'Previsione Costi' },
    { url: 'guida', name: 'Guida Software' },
    { url: 'domande', name: 'Domande' },
  ];

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const toggleFeedbackDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setFeedbackDrawerOpen(open);
    setNuoviFeedbacks(0);
    setUltimoFeedbackVisualizzato();
  };

  const setUltimoFeedbackVisualizzato = async () => {
    try {
      await axios.post("/api/feedbackseen", {}, {
        headers: utils.getAuthHeaders(),
        validateStatus: () => true,
      });
    } catch (err) {
      console.error(err);
    }
  }

  /* Link Drawer Pannello Controllo */
  const drawerContent = (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      style={{ backgroundColor: '#333', height: '100%', color: '#fff', padding: '16px' }}
    >

      <Link to="/op/pannellocontrollo" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center', margin: '8px' }}>
        <DashboardIcon style={{ marginRight: '8px' }} />
        <Typography variant="button" display="block">Pannello di Controllo</Typography>
      </Link>

      <Divider style={{ backgroundColor: '#555', margin: '8px 0' }} />

      <Link to="/op/amministrazione/opoperazioni" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center', margin: '8px' }}>
        <SettingsIcon style={{ marginRight: '8px' }} />
        <Typography variant="button" display="block">Operazioni Op</Typography>
      </Link>

      <Divider style={{ backgroundColor: '#555', margin: '8px 0' }} />

      <Link to="/op/amministrazione/fatturato" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center', margin: '8px' }}>
        <AttachMoneyIcon style={{ marginRight: '8px' }} />
        <Typography variant="button" display="block">Fatturato</Typography>
      </Link>

      <Divider style={{ backgroundColor: '#555', margin: '8px 0' }} />

      <Link to="/op/situazionegenerale" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center', margin: '8px' }}>
        <TimelineIcon style={{ marginRight: '8px' }} />
        <Typography variant="button" display="block">Situazione Generale</Typography>
      </Link>

      <Divider style={{ backgroundColor: '#555', margin: '8px 0' }} />

      <Link to="/op/contabilita" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center', margin: '8px' }}>
        <AccountBalanceIcon style={{ marginRight: '8px' }} />
        <Typography variant="button" display="block">Contabilità</Typography>
      </Link>

      <Divider style={{ backgroundColor: '#555', margin: '8px 0' }} />

      <Link to="/op/news" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center', margin: '8px' }}>
        <NewReleasesIcon style={{ marginRight: '8px' }} />
        <Typography variant="button" display="block">News</Typography>
      </Link>
    </div>
  );

  const feedbackDrawerContent = (
    <div
      role="presentation"
      onClick={toggleFeedbackDrawer(false)}
      onKeyDown={toggleFeedbackDrawer(false)}
      style={{ padding: '16px', maxHeight: '100%', overflowY: 'auto' }} // Aggiungi padding e overflow per lo scroll
    >
      <Typography variant="h6" style={{ margin: '16px', fontWeight: 'bold' }}>Feedback delle Aziende</Typography>
      <Divider sx={{ my: 2 }} />
      <List>
        {feedbacks.map((feedback, index) => (
          <React.Fragment key={index}>
            <ListItem>
              <ListItemText
                primary={feedback.name}
                secondary={feedback.text}
                primaryTypographyProps={{ style: { wordWrap: 'break-word' } }} // Gestione wrapping testo del titolo
                secondaryTypographyProps={{ style: { wordWrap: 'break-word', whiteSpace: 'pre-wrap' } }} // Gestione wrapping testo del contenuto
              />
            </ListItem>
            {index < feedbacks.length - 1 && <Divider style={{ backgroundColor: '#D3D3D3' }} />}
          </React.Fragment>
        ))}
      </List>
    </div>
  );

  return (
    <>
      <style>
        {`
          .operator-area {
            display: flex;
            justify-content: center;
          }

          .operator-card {
            border: 1px solid #ccc;
            padding: 16px;
            margin: 16px;
            text-align: center;
            min-width: 200px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
            transition: transform 0.2s;
            text-decoration: none;
            color: black;
            border-radius: 10px;
          }

          .operator-card:hover {
            transform: scale(1.05);
          }

          .username {
            font-weight: bold;
          }

          @media (max-width: 768px) {
            .operator-card {
              min-width: auto;
              width: 80%;
              font-size: 16px;
            }

            .operator-area {
              flex-direction: column;
              padding: 20px;
              margin-top: 2vh;
            }
          }

            .feedback-button {
            position: fixed;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1; /* Ensure it's above other elements */
          }
        `}
      </style>

      <Container maxWidth="md" style={{ position: 'relative' }}>
        <Box display="flex" alignItems="center" justifyContent="center" height="100%">
          <Box display={isAdmin ? "flex" : "none"} alignItems="center" justifyContent="center" height="100%" width="10%" style={{ marginTop: '20px', marginLeft: '0px' }}>
            <Tooltip title="Panello di Controllo" arrow>
              <IconButton
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                style={{ margin: '0px' }}
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center" height="100%" width="90%">
            <Typography variant="h4" gutterBottom align="center" style={{ marginTop: '30px', marginLeft: '0px' }}>
              Benvenuto/a nella tua Area Personale, <span className="username">{name}</span>
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Drawer Pannello Controllo */}
        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={toggleDrawer(false)}
        >
          {drawerContent}
        </Drawer>

        {/* Drawer per i feedback */}
        <Drawer
          anchor="left"
          open={feedbackDrawerOpen}
          onClose={toggleFeedbackDrawer(false)}
          sx={{ '& .MuiDrawer-paper': { width: '80vw', maxWidth: '600px', minWidth: '300px' } }} // Larghezza adattiva
        >
          {feedbackDrawerContent}
        </Drawer>
      </Container>

      <Container maxWidth="md" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '55vh' }}>
        <Box display="flex" flexWrap="wrap" justifyContent="space-between" className="operator-area">
          {links.filter(link => visible.includes(link.url)).map(link => (
            <Link to={'/op/' + link.url} className="operator-card">
              <h2>{link.name}</h2>
            </Link>
          ))}
        </Box>
      </Container>

      {/* Icona dei feedback fuori dal Container */}
      {isAdmin && (
        <Tooltip title="Feedback Aziende" arrow>
          <IconButton
            className="feedback-button"
            color="primary"
            onClick={toggleFeedbackDrawer(true)}
            aria-label="Feedback"
            sx={{ fontSize: 40 }}
          >
            <Badge badgeContent={nuoviFeedbacks} color="error">
              <FeedbackIcon fontSize="inherit" />
            </Badge>
          </IconButton>
        </Tooltip>
      )}
      {/* Aggiungi il componente Chat <Chat />   */}

    </>
  );
}

export default Op;
