import React, { useState, useEffect } from 'react';
import {
  Grid, Autocomplete, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Snackbar, CircularProgress,
  Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, IconButton, Box, Pagination, MenuItem, Select, Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import utils from '../../utils';

const OpAmmDipendenti = () => {
  const [aziende, setAziende] = useState([]);
  const [dipendenti, setDipendenti] = useState([]);
  const [allDipendenti, setAllDipendenti] = useState([]);
  const [selectedAzienda, setSelectedAzienda] = useState(null);
  const [selectedDipendente, setSelectedDipendente] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isAdding, setIsAdding] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [dipendenteToDelete, setDipendenteToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadAziende();
  }, []);

  useEffect(() => {
    if (page > Math.ceil(dipendenti.length / rowsPerPage) - 1) {
      setPage(0); // Resetta la pagina se il numero di dipendenti è inferiore rispetto alla pagina corrente
    }
  }, [dipendenti, rowsPerPage, page]);

  useEffect(() => {
    if (selectedAzienda) {
      loadDipendenti(selectedAzienda);
    }
  }, [selectedAzienda]);

  const loadAziende = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`/api/aziendeeop`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setAziende(res.data.aziende);
      } else {
        setError(error);
      }
    } catch (error) {
      setError(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  };

  const loadDipendenti = async (userId) => {
    setLoading(true);
    try {
      const res = await axios.get('/api/dipendenti?id=' + userId, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setAllDipendenti(res.data.dipendenti);
        setDipendenti(res.data.dipendenti);
      } else {
        setError(error);
      }
    } catch (error) {
      setError(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  };

  const handleRowClick = (dipendente) => {
    setSelectedDipendente(dipendente);
    setIsAdding(false); // Imposta isAdding su false quando si seleziona un dipendente esistente
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedDipendente(null);
  };

  const handleSaveChanges = async () => {
    if (selectedDipendente.data_assunzione && selectedDipendente.data_assunzione.length != 10) {
      setError("Inserire la data di assunzione in forma: 01/01/2024");
      return;
    }
    if (selectedDipendente.data_cessazione && selectedDipendente.data_cessazione.length != 10) {
      setError("Inserire la data di scadenza contratto in forma: 01/01/2024");
      return;
    }
    setOpenDialog(false);
    setLoading(true);
    try {
      const res = await axios.post(`/api/${isAdding ? 'crea' : 'aggiorna'}dipendente`,
        { id: selectedDipendente.id, dipendente: selectedDipendente }, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        return window.location.reload();
      } else {
        setError(error);
      }
    } catch (error) {
      setError(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1); // Modifica qui in modo che `Pagination` e la pagina siano sincronizzati correttamente
  };

  // Funzione per cambiare il numero di righe per pagina
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Torna alla prima pagina se si cambia il numero di righe per pagina
  };

  const handleAutocompleteChange = (event) => {
    const value = event.target.value;
    if (value && value.trim() !== '') {
      setDipendenti(
        allDipendenti.filter((dipendente) =>
          `${dipendente.nome} ${dipendente.cognome}`.toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      setDipendenti(allDipendenti); // Mostra tutti i dipendenti se non c'è input
    }
    setPage(0);
    setSearchTerm(value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedDipendente({
      ...selectedDipendente,
      [name]: value,
    });
  };

  const filteredDipendenti = dipendenti.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  // Funzioni Per il Dialog di Delete Dipendente
  const handleDelete = (dipendente) => {
    setDipendenteToDelete(dipendente);
    setOpenDeleteDialog(true);
  };

  const handleConfirmDelete = async () => {
    setOpenDeleteDialog(false);
    setLoading(true);
    try {
      const res = await axios.post("/api/eliminadipendente", { id: dipendenteToDelete.id }, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        return window.location.reload();
      } else {
        setError(error);
      }
    } catch (error) {
      setError(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  };

  const handleCancelDelete = () => {
    setOpenDeleteDialog(false);
    setDipendenteToDelete(null);
  };

  return (
    <Grid container spacing={3} style={{ padding: '10px' }}>
      <Grid item xs={12}>
        <Typography variant="h5" align="center">
          Dipendenti per Azienda
        </Typography>
      </Grid>

      <Divider sx={{ my: 2 }} />

      {/* Azienda */}
      <Grid item xs={12} md={6}>
        <Paper style={{ padding: '10px', borderRadius: '10px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
          <Autocomplete
            id="azienda"
            value={selectedAzienda}
            onChange={(event, newValue) => {
              setSelectedAzienda(newValue);
              setSelectedDipendente(null); // Reset selezione dipendente
            }}
            options={aziende.map((az) => az.id)}
            getOptionLabel={(id) => {
              const a = aziende.filter(az => az.id == id)[0];
              return `${a.nome} (${a.id})`;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Azienda"
                variant="outlined"
              />
            )}
            fullWidth
            style={{ borderRadius: '10px' }}
          />
        </Paper>
      </Grid>

      {/* Dipendente */}
      <Grid item xs={12} md={6}>
        <Paper style={{ padding: '10px', borderRadius: '10px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', display: 'flex', alignItems: 'center', position: 'relative' }}>
          <div style={{ flex: 1, marginRight: '10px' }}>
            <TextField
              value={searchTerm}
              onChange={handleAutocompleteChange}
              label="Cerca per nome/cognome"
              fullWidth
              style={{ borderRadius: '10px' }}
            />
          </div>
          <IconButton
            style={{ position: 'relative', top: '0', right: '0' }}
            color="primary"
            onClick={() => {
              setIsAdding(true);
              setSelectedDipendente({
                nome: '',
                cognome: '',
                data_nascita: '',
                luogo_nascita: '',
                nazionalita: '',
                cittadinanza: '',
                cod_fiscale: '',
                residenza: '',
                domicilio: '',
                data_assunzione: '',
                data_cessazione: '',
                tipo_contratto: '',
                note: ''
              });
              setOpenDialog(true);
            }}
          >
            <AddIcon />
          </IconButton>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper style={{ padding: '10px', borderRadius: '10px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
          {loading ? <CircularProgress sx={{ display: 'block', margin: '0 auto' }} /> :
            <TableContainer component={Paper} style={{ borderRadius: '10px' }}>
              <Table>
                <TableHead>
                  <TableRow style={{ backgroundColor: '#333', color: '#fff' }}>
                    <TableCell style={{ width: '20%', color: '#fff', fontWeight: 'bold' }}>Cognome</TableCell>
                    <TableCell style={{ width: '20%', color: '#fff', fontWeight: 'bold' }}>Nome</TableCell>
                    <TableCell style={{ width: '20%', color: '#fff', fontWeight: 'bold' }}>Data di Nascita</TableCell>
                    <TableCell style={{ width: '20%', color: '#fff', fontWeight: 'bold' }}>Codice Fiscale</TableCell>
                    <TableCell style={{ width: '20%', color: '#fff', fontWeight: 'bold' }}>Tipo Contratto</TableCell>
                    <TableCell style={{ width: '10%', color: '#fff', fontWeight: 'bold' }}>Elimina</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {filteredDipendenti.map((dipendente) => (
                    <TableRow
                      key={dipendente.id}
                      hover
                      onClick={() => handleRowClick(dipendente)}
                      style={{ backgroundColor: '#f5f5f5' }}
                    >
                      <TableCell>{dipendente.cognome}</TableCell>
                      <TableCell>{dipendente.nome}</TableCell>
                      <TableCell>{dipendente.data_nascita}</TableCell>
                      <TableCell>{dipendente.cod_fiscale}</TableCell>
                      <TableCell>{dipendente.tipo_contratto}</TableCell>
                      <TableCell>
                        <IconButton onClick={(e) => { e.stopPropagation(); handleDelete(dipendente); }}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>}

          {/* Paginazione */}
          <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="center" alignItems="center" mt={2}>
            <Select
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
              variant="outlined"
              style={{ marginRight: '8px', maxWidth: '80px', height: '35px', marginBottom: { xs: '8px', sm: '0' } }}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
            <Pagination
              count={Math.ceil(dipendenti.length / rowsPerPage)} // Numero totale di pagine
              page={page + 1} // `Pagination` usa un base-1 system
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
          </Box>
        </Paper>
      </Grid>

      {/* Dialog per Conferma Elimina Dipendente */}
      <Dialog open={openDeleteDialog} onClose={handleCancelDelete}>
        <DialogTitle>Conferma</DialogTitle>
        <DialogContent>
          Sei sicuro di voler eliminare il dipendente {dipendenteToDelete?.nome} {dipendenteToDelete?.cognome}?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">Annulla</Button>
          <Button onClick={handleConfirmDelete} color="secondary">Conferma</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog per Dett Dipendente e per Add */}
      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="md" PaperProps={{ style: { borderRadius: '10px' } }}>
        <DialogTitle>
          {isAdding ? 'Aggiungi Nuovo Dipendente' : 'Dettagli Dipendente'}
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseDialog}
            aria-label="close"
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={{
            maxHeight: '80vh',
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            scrollbarColor: 'transparent transparent'
          }}
        >
          {selectedDipendente && (
            <Box padding={2}>
              <Grid container spacing={2}>
                {/* Colonna Sinistra */}
                <Grid item xs={12} md={6}>
                  <Typography variant="body1"><strong>Nome:</strong></Typography>
                  <TextField
                    fullWidth
                    name="nome"
                    value={selectedDipendente.nome}
                    onChange={handleInputChange}
                    variant="outlined"
                    style={{ borderRadius: '10px' }}
                    required
                  />
                  <Typography variant="body1"><strong>Cognome:</strong></Typography>
                  <TextField
                    fullWidth
                    name="cognome"
                    value={selectedDipendente.cognome}
                    onChange={handleInputChange}
                    variant="outlined"
                    style={{ borderRadius: '10px' }}
                    required
                  />
                  <Typography variant="body1"><strong>Data di Nascita:</strong></Typography>
                  <TextField
                    fullWidth
                    name="data_nascita"
                    value={selectedDipendente.data_nascita}
                    onChange={handleInputChange}
                    variant="outlined"
                    style={{ borderRadius: '10px' }}
                  />
                  <Typography variant="body1"><strong>Luogo di Nascita:</strong></Typography>
                  <TextField
                    fullWidth
                    name="luogo_nascita"
                    value={selectedDipendente.luogo_nascita}
                    onChange={handleInputChange}
                    variant="outlined"
                    style={{ borderRadius: '10px' }}
                  />
                  <Typography variant="body1"><strong>Nazionalità:</strong></Typography>
                  <TextField
                    fullWidth
                    name="nazionalita"
                    value={selectedDipendente.nazionalita}
                    onChange={handleInputChange}
                    variant="outlined"
                    style={{ borderRadius: '10px' }}
                  />
                  <Typography variant="body1"><strong>Cittadinanza:</strong></Typography>
                  <TextField
                    fullWidth
                    name="cittadinanza"
                    value={selectedDipendente.cittadinanza}
                    onChange={handleInputChange}
                    variant="outlined"
                    style={{ borderRadius: '10px' }}
                  />
                </Grid>
                {/* Colonna Destra */}
                <Grid item xs={12} md={6}>
                  <Typography variant="body1"><strong>Codice Fiscale:</strong></Typography>
                  <TextField
                    fullWidth
                    name="cod_fiscale"
                    value={selectedDipendente.cod_fiscale}
                    onChange={handleInputChange}
                    variant="outlined"
                    style={{ borderRadius: '10px' }}
                    required
                  />
                  <Typography variant="body1"><strong>Residenza:</strong></Typography>
                  <TextField
                    fullWidth
                    name="residenza"
                    value={selectedDipendente.residenza}
                    onChange={handleInputChange}
                    variant="outlined"
                    style={{ borderRadius: '10px' }}
                  />
                  <Typography variant="body1"><strong>Domicilio:</strong></Typography>
                  <TextField
                    fullWidth
                    name="domicilio"
                    value={selectedDipendente.domicilio}
                    onChange={handleInputChange}
                    variant="outlined"
                    style={{ borderRadius: '10px' }}
                  />
                  <Typography variant="body1"><strong>Data Assunzione:</strong></Typography>
                  <TextField
                    fullWidth
                    name="data_assunzione"
                    value={selectedDipendente.data_assunzione || ''}
                    onChange={handleInputChange}
                    variant="outlined"
                    style={{ borderRadius: '10px' }}
                  />
                  <Typography variant="body1"><strong>Data Scadenza Contratto:</strong></Typography>
                  <TextField
                    fullWidth
                    name="data_cessazione"
                    value={selectedDipendente.data_cessazione || ''}
                    onChange={handleInputChange}
                    variant="outlined"
                    style={{ borderRadius: '10px' }}
                  />
                  <Typography variant="body1"><strong>Tipo Contratto:</strong></Typography>
                  <TextField
                    fullWidth
                    name="tipo_contratto"
                    value={selectedDipendente.tipo_contratto}
                    onChange={handleInputChange}
                    variant="outlined"
                    style={{ borderRadius: '10px' }}
                  />
                </Grid>
                {/* Colonna Inferiore per "Note" */}
                <Grid item xs={12}>
                  <Typography variant="body1"><strong>Note:</strong></Typography>
                  <TextField
                    fullWidth
                    name="note"
                    value={selectedDipendente.note}
                    onChange={handleInputChange}
                    variant="outlined"
                    style={{ borderRadius: '10px', marginTop: '8px' }}
                    multiline
                    rows={4}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">Chiudi</Button>
          <Button
            onClick={() => handleSaveChanges()}
            color="primary"
            variant="contained"
          >
            {isAdding ? 'Aggiungi' : 'Salva'}
          </Button>
        </DialogActions>
      </Dialog>
      {error && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() => setError('')}
          message={error}
        />
      )}

    </Grid >
  );
};

export default OpAmmDipendenti;
