import React, { useEffect, useState } from "react";
import {
  Typography, Grid, CircularProgress, TableContainer, Table, TableHead, TableBody, TableRow, TableCell,
  Select, MenuItem, Divider, Box, Tooltip, TextField, FormControl, InputLabel, OutlinedInput, ButtonBase
} from "@mui/material";
import axios from "axios";
import utils from "../../utils";
import { TrendingUp, TrendingDown } from '@mui/icons-material';
import { Link } from "react-router-dom";

const PagheFatte = () => {
  const now = new Date();
  const currentMonth = now.getMonth();
  const currentYear = now.getFullYear();
  const anni = ['2024'];
  while (now.getFullYear() > anni[anni.length - 1]) {
    anni.push((Number(anni[anni.length - 1]) + 1).toString());
  }
  const [pagheFatteData, setPagheFatteData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTermOp, setSearchTermOp] = useState('');
  const [sortAsc, setSortAsc] = useState(true);
  const [avgDays, setAvgDays] = useState(0); // Media giorni corrente
  const [prevAvgDays, setPrevAvgDays] = useState(0); // Media giorni mese precedente
  const [isAdmin, setIsAdmin] = useState(false); // Stato per l'amministratore

  const annoTarget = selectedMonth == 0 ? Number(selectedYear) - 1 : selectedYear;
  const meseTarget = selectedMonth == 0 ? 11 : Number(selectedMonth) - 1;

  useEffect(() => {
    loadData();
    loadPagheFatte();
  }, [selectedMonth, selectedYear]);

  useEffect(() => {
    if (pagheFatteData.length > 0) {
      calculateAverageDays(pagheFatteData);
      setPrevAvgDays(5); // Imposta la media precedente per confronto
    }
  }, [pagheFatteData]);

  const loadData = async () => {
    try {
      const res = await axios.get("/api/me", {
        headers: utils.getAuthHeaders(),
        validateStatus: () => true,
      });
      if (res.status === 200) {
        const { success, me } = res.data;
        if (success && !me.op) {
          window.location.href = "/cli";
          return;
        }
        if (success) {
          setIsAdmin(me.aree.split(',').includes('amministrazione'));
          return; // stay on the page
        }
      }
    } catch (_) { }
    if (process.env.REACT_APP_ENV !== "test") {
      window.location.href = "/login";
    }
  };

  // Funzione per caricare le paghe completate
  const loadPagheFatte = async () => {
    try {
      const res = await axios.get(`/api/paghefatte?anno=${annoTarget}&mese=${meseTarget}`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error, data } = res.data;
      if (success) {
        setPagheFatteData(data);
      } else {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  }

  // Funzione per ordinare colonna da A-Z e Z-A
  const handleSort = () => {
    setPagheFatteData(prevData => [...prevData].sort((a, b) => sortAsc ? a.nome.localeCompare(b.nome) : b.nome.localeCompare(a.nome)));
    setSortAsc(prevSortAsc => !prevSortAsc);
  };

  // Funzione per calcolare i giorni per pratica elaborata
  const calculateDaysDifference = (dataInvio, dataElaborazione) => {
    const invioDate = new Date(dataInvio);
    const elaborazioneDate = new Date(dataElaborazione);
    return Math.ceil((elaborazioneDate - invioDate) / (1000 * 60 * 60 * 24)); // Differenza in giorni
  };

  // Funzione per calcolare la media dei giorni di quel mese 
  const calculateAverageDays = (data) => {
    const totalDays = data.reduce((acc, row) => {
      if (row.dataInvio && row.dataElaborazione) {
        return acc + calculateDaysDifference(row.dataInvio, row.dataElaborazione);
      }
      return acc;
    }, 0);
    const totalEntries = data.filter(row => row.dataInvio && row.dataElaborazione).length;
    setAvgDays(totalEntries > 0 ? totalDays / totalEntries : 0);
  };

  return (
    <Box p={3}>
      <Typography variant="h5" align="center" gutterBottom>Paghe Fatte</Typography>
      <Divider sx={{ my: 2 }} />
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} md={4} lg={3}>
          <TextField
            label="Cerca Azienda"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <TextField
            label="Cerca Incaricato"
            variant="outlined"
            value={searchTermOp}
            onChange={(e) => setSearchTermOp(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Select value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)} fullWidth>
            {Array.from({ length: 12 }, (_, i) => (
              <MenuItem key={i} value={i}>
                {new Date(0, i).toLocaleString("default", { month: "long" }).replace(/^./, str => str.toUpperCase())}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Select value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)} fullWidth>
            {anni.map(a => <MenuItem key={a} value={a}>{a}</MenuItem>)}
          </Select>
        </Grid>
      </Grid>
      <TableContainer component={Box} sx={{ borderRadius: '8px', marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: '#333' }}>
              <Tooltip title={sortAsc ? "Ordina A-Z" : "Ordina Z-A"}>
                <TableCell style={{ color: '#fff', textAlign: 'center', cursor: 'pointer' }} onClick={handleSort}>
                  Azienda {sortAsc ? '▲' : '▼'}
                </TableCell>
              </Tooltip>
              <TableCell style={{ color: '#fff', textAlign: 'center' }}>N Dipendenti</TableCell>
              <TableCell style={{ color: '#fff', textAlign: 'center' }}>Data Invio</TableCell>
              <TableCell style={{ color: '#fff', textAlign: 'center' }}>Data Elaborazione</TableCell>
              <TableCell style={{ color: '#fff', textAlign: 'center' }}>Presa in Carico da</TableCell>
              {isAdmin && (
                <TableCell style={{ color: '#fff', textAlign: 'center' }}>
                  Elaborato in
                  {avgDays < prevAvgDays ? (
                    <TrendingUp style={{ color: 'green', marginLeft: 5 }} />
                  ) : (
                    <TrendingDown style={{ color: 'red', marginLeft: 5 }} />
                  )}
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow><TableCell colSpan={6} align="center"><CircularProgress /></TableCell></TableRow>
            ) : (
              pagheFatteData
                .filter(row => row.nome.toLowerCase().includes(searchTerm.toLowerCase())) // Filtro per la ricerca
                .filter(row => row.presaInCarico.toLowerCase().includes(searchTermOp.toLowerCase())) // Filtro per la ricerca
                .map(row => (
                  <TableRow key={row.nome}>
                    <TableCell style={{ textAlign: 'center' }}>
                      <ButtonBase
                        component={Link}
                        to={`/op/paghe/presenze?id=${row.userId}&m=${meseTarget}&y=${annoTarget}`}
                      >
                        {row.nome}
                      </ButtonBase>
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{row.dipendenti}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{row.dataInvio ? utils.revDate(row.dataInvio) : ""}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{row.dataElaborazione ? utils.revDate(row.dataElaborazione) : ""}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{row.presaInCarico ? row.presaInCarico : ""}</TableCell>
                    {isAdmin && (
                      <TableCell style={{ textAlign: 'center' }}>
                        {row.dataInvio && row.dataElaborazione
                          ? `${calculateDaysDifference(row.dataInvio, row.dataElaborazione)} giorni`
                          : "N/A"}
                      </TableCell>
                    )}
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PagheFatte;
