import React, { useState, useEffect } from 'react';
import {
  Autocomplete, TextField, Grid, Paper, Avatar, Button, Select, MenuItem, FormControl, InputLabel, Typography,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, TablePagination,
  Dialog, DialogTitle, DialogContent, DialogActions, Pagination, PaginationItem, Box, Snackbar, CircularProgress
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import { Container, styled } from '@mui/system';
import axios from 'axios';
import utils from '../../utils';

// Styled component per il titolo
const Title = styled(Typography)({
  textAlign: 'center',
  marginBottom: '24px',
  fontWeight: 'bold',
  color: 'black'
});

// Styled table head con riga scura
const StyledTableHead = styled(TableRow)({
  backgroundColor: '#424242',
  '& th': {
    color: 'white',
    fontWeight: 'bold',
  },
});

const PagBustePaga = () => {
  const now = new Date();

  const anni = ['2024'];
  while (now.getFullYear() > anni[anni.length - 1]) {
    anni.push((Number(anni[anni.length - 1]) + 1).toString());
  }

  const [searchTerm, setSearchTerm] = useState('');
  const [meseSelezionato, setMeseSelezionato] = useState(now.getMonth());
  const [annoSelezionato, setAnnoSelezionato] = useState(now.getFullYear());
  const [statoBusta, setStatoBusta] = useState('Tutte');
  const [pagina, setPagina] = useState(0);
  const [righePerPagina, setRighePerPagina] = useState(5);
  const [openDialog, setOpenDialog] = useState(false);
  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const [bustaDaApprovare, setBustaDaApprovare] = useState(null);
  const [modificaTesto, setModificaTesto] = useState('');
  const [bustePaga, setBustePaga] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  // Funzione per caricare le buste paga dal backend
  const caricaBustePaga = async (a, m) => {
    setLoading(true);
    try {
      const res = await axios.get(`/api/paghe?anno=${a}&mese=${m}`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setBustePaga(res.data.paghe);
      } else {
        setError(error);
      }
    } catch (error) {
      setError('Errore nel caricamento delle buste paga: ' + error.message);
    }
    setLoading(false);
  };

  // Funzione per scaricare le buste paga dal backend
  const handleDownloadBustaPaga = async (docId) => {
    try {
      await utils.cliDownload(docId);
    } catch (error) {
      setError('File non trovato');
      console.error('Errore nel download della busta paga:', error);
    }
  };

  // Gestione apertura/chiusura dialog
  const handleOpenDialog = (busta) => {
    setModificaTesto(busta.richiesta || ''); // Passa il testo della richiesta di modifica se presente
    setBustaDaApprovare(busta);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setBustaDaApprovare(null);
    setModificaTesto('');
  };

  // Gestione apertura/chiusura dialog di approvazione
  const handleOpenApproveDialog = (busta) => {
    setBustaDaApprovare(busta);
    setOpenApproveDialog(true);
  };

  const handleCloseApproveDialog = () => {
    setOpenApproveDialog(false);
    setBustaDaApprovare(null);
  };

  // Funzione per approvare la bozza
  const handleApproveBozza = async () => {
    setLoading(true);
    try {
      const res = await axios.post('/api/setpaghedefinitiva', {
        anno: annoSelezionato, mese: meseSelezionato,
        index: bustaDaApprovare.index
      }, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        caricaBustePaga(annoSelezionato, meseSelezionato); // Aggiorna la lista delle buste paga dopo l'approvazione
      } else {
        setError(error);
      }
      handleCloseApproveDialog();
    } catch (error) {
      setError('Errore nell\'approvazione della bozza: ' + error.message);
    }
    setLoading(false);
  };

  // Funzione per inviare la richiesta di modifica
  const handleSendModifica = async () => {
    setLoading(true);
    try {
      const res = await axios.post('/api/setpagherichiesta', {
        anno: annoSelezionato, mese: meseSelezionato,
        index: bustaDaApprovare.index, richiesta: modificaTesto
      }, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        caricaBustePaga(annoSelezionato, meseSelezionato); // Aggiorna la lista delle buste paga dopo l'approvazione
      } else {
        setError(error);
      }
      handleCloseDialog();
    } catch (error) {
      setError('Errore nell\'invio della richiesta di modifica:', error);
    }
    setLoading(false);
  };

  // Filtraggio delle buste paga in base al mese e all'anno selezionato
  const busteFiltrate = bustePaga
    .filter(busta => busta.dipendente.toLowerCase().includes(searchTerm.toLowerCase()))
    .filter(busta => {
      if (statoBusta == 'Tutte') {
        return true;
      } else if (statoBusta == 'Bozza') {
        return !busta.definitiva;
      } else {
        return busta.definitiva;
      }
    });

  // Carica i dipendenti e le buste paga al montaggio del componente
  useEffect(() => {
    if ((meseSelezionato + 1) && annoSelezionato) {
      caricaBustePaga(annoSelezionato, meseSelezionato);
    }
  }, [annoSelezionato, meseSelezionato]);

  // Gestione Paginazione fuori dal Container
  const handlePageChange = (event, value) => {
    setPagina(value - 1); // Pagination di MUI è 1-based, quindi sottraiamo 1
  };

  const handleRowsPerPageChange = (event) => {
    setRighePerPagina(parseInt(event.target.value, 10));
    setPagina(0); // Resetta la pagina alla prima quando cambi il numero di righe per pagina
  };

  return (
    <Container maxWidth="xxl">
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} marginTop={2}>
          <Title variant="h4">Buste Paga</Title>
        </Grid>

        {/* Selezione Dipendente */}
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            value={searchTerm}
            onChange={(event) => {
              const newValue = event.target.value;
              setSearchTerm(newValue);
            }}
            fullWidth
            label="Dipendente"
            variant="outlined"
            sx={{ flexGrow: 1, mb: { xs: 1, sm: 0 }, mr: { sm: 1 } }}
          />
        </Grid>

        {/* Selezione Anno */}
        <Grid item xs={12} sm={6} md={3}>
          <Select value={annoSelezionato} onChange={(e) => setAnnoSelezionato(e.target.value)} fullWidth>
            {anni.map(a => <MenuItem key={a} value={a}>{a}</MenuItem>)}
          </Select>
        </Grid>

        {/* Selezione Mese */}
        <Grid item xs={12} sm={6} md={3}>
          <Select value={meseSelezionato} onChange={(e) => setMeseSelezionato(e.target.value)} fullWidth>
            {Array.from({ length: 12 }, (_, i) => (
              <MenuItem key={i} value={i}>
                {new Date(0, i).toLocaleString("default", { month: "long" }).replace(/^./, str => str.toUpperCase())}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        {/* Selezione Stato Busta */}
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <InputLabel id="stato-busta-label">Stato Busta</InputLabel>
            <Select
              labelId="stato-busta-label"
              id="stato-busta"
              value={statoBusta}
              label="Stato Busta"
              onChange={(e) => setStatoBusta(e.target.value)}
            >
              <MenuItem value="Tutte">Tutte</MenuItem>
              <MenuItem value="Bozza">Bozza</MenuItem>
              <MenuItem value="Definitiva">Definitiva</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Anagrafica Dipendente Selezionato
        {dipendenteSelezionato && (
          <Grid item xs={12}>
            <Paper variant="outlined" style={{ padding: '8px', display: 'flex', alignItems: 'flex-start', marginTop: '8px' }}>
              <Avatar style={{ marginTop: '8px' }}>
                {dipendenteSelezionato.nome.split(' ').map(n => n[0]).join('')}
              </Avatar>
              <div style={{ marginLeft: '16px' }}>
                <h3>{`${dipendenteSelezionato.nome} ${dipendenteSelezionato.cognome}`}</h3>
                {dipendenteSelezionato.anagrafica && (
                  <>
                    <p>{`Ruolo: ${dipendenteSelezionato.anagrafica.ruolo}`}</p>
                    <p>{`Orario: ${dipendenteSelezionato.anagrafica.orario}`}</p>
                  </>
                )}
              </div>
            </Paper>
          </Grid>
        )}  */}

        {/* Tabella Buste Paga */}

        <Grid item xs={12} style={{ marginTop: '16px' }}>
          <TableContainer component={Paper}>
            <Table aria-label="buste paga table">
              <TableHead sx={{ '& th': { textAlign: 'center', width: '20%' } }}>
                <StyledTableHead>
                  <TableCell>Dipendente</TableCell>
                  <TableCell>Stato</TableCell>
                  <TableCell align="center">Richieste</TableCell>
                  <TableCell align="center">Azioni</TableCell>
                </StyledTableHead>
              </TableHead>
              <TableBody sx={{ '& td': { textAlign: 'center', width: '20%' } }}>
                {busteFiltrate
                  .slice(pagina * righePerPagina, pagina * righePerPagina + righePerPagina)
                  .map((busta, index) => (
                    <TableRow key={index}>
                      <TableCell>{busta.dipendente}</TableCell>
                      <TableCell>{busta.definitiva ? 'Definitiva' : 'Bozza'}</TableCell>
                      <TableCell align="center">
                        <>
                          {busta.definitiva ? <CheckCircleIcon style={{ color: 'green' }} /> : <IconButton color="primary" onClick={() => handleOpenApproveDialog(busta)}>
                            <CheckCircleIcon />
                          </IconButton>}
                          {(!busta.definitiva) ? <IconButton color="warning" onClick={() => handleOpenDialog(busta)}>
                            <WarningIcon />
                          </IconButton> : <div />}
                        </>
                      </TableCell>
                      <TableCell align="center">
                        {/* Bottone Download */}
                        <IconButton color={busta.documento ? 'success' : 'inherit'} onClick={() => handleDownloadBustaPaga(busta.documento)} disabled={!busta.documento}>
                          <DownloadIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Paginazione */}
          <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="center" alignItems="center" mt={2}>
            <FormControl variant="outlined" size="small">
              <Select
                labelId="rows-per-page-label"
                id="rows-per-page"
                value={righePerPagina}
                onChange={handleRowsPerPageChange}
                variant="outlined"
                style={{ marginRight: '8px', maxWidth: '80px', height: '35px' }}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
            <Pagination
              count={Math.ceil(busteFiltrate.length / righePerPagina)} // Numero totale di pagine
              page={pagina + 1} // `Pagination` usa un sistema a base-1
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
              showFirstButton
              showLastButton
              renderItem={(item) => (
                <PaginationItem
                  {...item}
                  component="button"
                />
              )}
            />
          </Box>
        </Grid>
      </Grid>

      {/* Dialog per le richieste di modifica */}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="xs" fullWidth >
        <DialogTitle>Richieste di Modifica</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="modifica"
            label="Testo della Modifica"
            type="text"
            fullWidth
            multiline
            rows={8}
            value={modificaTesto}
            onChange={(e) => setModificaTesto(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Annulla
          </Button>
          <Button onClick={handleSendModifica} color="primary">
            Invia
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog per l'approvazione della bozza */}
      <Dialog open={openApproveDialog} onClose={handleCloseApproveDialog}>
        <DialogTitle>Conferma Approvazione</DialogTitle>
        <DialogContent>
          <p>Sei sicuro di voler approvare questa bozza?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseApproveDialog} color="primary">
            Annulla
          </Button>
          <Button onClick={handleApproveBozza} color="primary">
            Approva
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar per gli errori */}
      {error && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() => setError('')}
          message={error}
        />
      )}

      {/* CircularProgress per il caricamento */}
      {loading && (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      )}

    </Container>
  );
};

export default PagBustePaga;
